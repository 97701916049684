import React, {useEffect, useState} from 'react';
import CustomCard from "../../../components/generic/CustomCard";
import {theme} from "../../../style/theme";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {dispatchToStore} from "../../../store/configureStore";
import {stockProductsLoad, stockProductsUpsert} from "../../../store/slices/stock/products";
import {useParams} from "react-router";
import {Button, Stack, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {Link as RouterLink} from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import {DisplayExpectedProps} from "../../../components/stock/products";
import {stockPropertiesLoad} from "../../../store/slices/stock/properties";


export default function ProductEditPage() {

    const {selectedProductId} = useParams()

    const productList = useSelector(store => store.stockProducts.items)

    useEffect(() => {
        // loading page effect
        dispatchToStore(stockProductsLoad({}))
        dispatchToStore(stockPropertiesLoad({}))

        // unloading page effect
        return () => {

        }

    }, []);

    let defaultProduct = {}
    if (Number(selectedProductId) === -1){
        defaultProduct = {
            product_id: -1,
            name: "new",
            desc: "new",
            expected_props: {}
        }
    } else {
        defaultProduct = productList[selectedProductId]
    }

    const [product, setProduct] = useState(defaultProduct)

    // ---
    function onUpsertItemRow(row) {
        const newProduct = {
            product_id: row["product_id"],
            name: row["name"],
            desc: row["desc"],
            expected_props: row["expected_props"],
        }
        dispatchToStore(stockProductsUpsert(newProduct))
    }

    function onChangeName(name) {
        setProduct({...product, "name": name})
    }

    function onChangeDescription(desc) {
        setProduct({...product, "desc": desc})
    }

    function onAddKey(key) {
        const k = {...product.expected_props}
        k[key] = {}

        setProduct({...product, "expected_props": k})
    }

    function onDeleteKey(key) {
        const k = {...product.expected_props}
        delete k[key]

        setProduct({...product, "expected_props": k})
    }

    return (
        <Grid container spacing={theme.spacing(4)} sx={{padding: theme.spacing(2)}}>
            <Grid item xs={12}>
                <CustomCard caption={"Edit Product Details"}>
                    <Stack direction={"column"} spacing={2}>

                        <TextField id={"name"} label={"Name"} value={product["name"]}
                                   onChange={(e) => onChangeName(e.target.value)}/>

                        <TextField id={"desc"} label={"Description"} value={product["desc"]}
                                   onChange={(e) => onChangeDescription(e.target.value)}/>

                        <TextField id={"modified_at"} label={"Modified at"} value={product["modified_at"]}
                                   disabled={true} />

                        <DisplayExpectedProps
                            expectedProps={product["expected_props"]}
                            editable={true}
                            onAddKey={onAddKey}
                            onDeleteKey={onDeleteKey}
                        />

                        <Stack direction={"row"} alignItems={"center"} gap={1} justifyContent={"center"}>

                            <Button size={"large"}
                                    color={"primary"}
                                    sx={{marginTop: 4}}
                                    component={RouterLink}
                                    variant={"outlined"}
                                    to={'/settings/products'}

                                    onClick={() => {
                                        onUpsertItemRow(product)
                                    }}>
                                <SaveIcon sx={{marginRight: 2}}/>
                                Save
                            </Button>

                            <Button size={"large"}
                                    color={"primary"}
                                    sx={{marginTop: 4}}
                                    component={RouterLink}
                                    to={'/settings/products'}

                                    onClick={() => {

                                    }}>
                                <CloseIcon sx={{marginRight: 2}}/>
                                Cancel
                            </Button>


                        </Stack>
                    </Stack>
                </CustomCard>
            </Grid>


        </Grid>

    )


}

