import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {userLoginLogout} from "../../store/slices/userLogin";
import {Card, CardContent, Container} from "@mui/material";


export default function LogoutPage() {
    const dispatch = useDispatch();

    const userLogin = useSelector(state => state.userLogin)
    if (userLogin.loggedIn) {
        dispatch(userLoginLogout())
    }

    return (
        <Container maxWidth="md" sx={{padding: 4, marginTop: 10}}>
            <Card>
                <CardContent>
                    <h1>Logout</h1>
                    <p>You are logged out now</p>

                </CardContent>
            </Card>
        </Container>
    );

};

