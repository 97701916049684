import {isDebugEnvironment} from "../generic/utils";

export const api_version = "0.2.4"
export const qr_code_size_mm = 15

let backend_api_url = "http://localhost:8080/api.php"
if (!isDebugEnvironment()) {
    const host = window.location.host // ex: warehouse.sensry.de
    const protocol = window.location.protocol // ex: http:  or https:

    // backend_api_url = "https://sry-warehouse-api.metagons-software.de/api.php"
    backend_api_url = protocol + "//" + host + "/api/api.php"
}
console.log("api url", backend_api_url)

export function getApiUrl(){
    return backend_api_url
}

export function getHostingUrl(){
    const host = window.location.host // ex: warehouse.sensry.de
    const protocol = window.location.protocol // ex: http:  or https:
    return protocol + "//" + host
}