import {HashRouter, Navigate, Route, Routes} from 'react-router-dom';

import {createBrowserHistory} from 'history';
import DashBoardPage from './pages/Dashboard/DashboardPage';
import LoginPage from "./pages/User/LoginPage";
import LogoutPage from "./pages/User/LogoutPage";
import RegisterPage from "./pages/User/RegisterPage";
import UserAccountPage from "./pages/User/UserAccount";
import configureAppStore, {dispatchToStore} from "./store/configureStore";
import {Provider, useSelector} from "react-redux";
import AboutPage from "./pages/About/AboutPage";
import PageHeader from "./components/Header/Header";
import LeftMenu from "./components/Header/LeftMenu";
import UserManagementPage from "./pages/User/UserManagementPage"
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import {useEffect, useState} from "react";
import {
    configurationBootstrapApp,
} from "./store/slices/configuration";
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import {SnackBar} from "./components/Common/SnackBar";
import {SnackbarProvider} from 'notistack';
import StockDevicesPage from "./pages/Stock/StockDevicesPage";
import ProductDetailsPage from "./pages/GlobalSettings/products/ProductDetailsPage";
import ProductEditPage from "./pages/GlobalSettings/products/ProductEditPage";
import ProductDeletePage from "./pages/GlobalSettings/products/ProductDeletePage";
import StockDeviceDeletePage from "./pages/Stock/StockDeviceDeletePage";
import StockDeviceEditPage from "./pages/Stock/StockDeviceEditPage";
import StockDeviceDetailsPage from "./pages/Stock/StockDeviceDetailsPage";
import ProductsSettingsPage from "./pages/GlobalSettings/products/ProductsPage";
import LocationsSettingsPage from "./pages/GlobalSettings/locations/LocationsPage";
import PropertiesSettingsPage from "./pages/GlobalSettings/properties/PropertiesPage";
import DeviceInfo from "./pages/Stock/DeviceInfo";
import ExportSettingsPage from "./pages/GlobalSettings/export/ExportSettingsPage";
import {userLoginRestartFromSession} from "./store/slices/userLogin";
import PropertiesDetailsPage from "./pages/GlobalSettings/properties/PropertiesDetailsPage";
import PropertiesEditPage from "./pages/GlobalSettings/properties/PropertiesEditPage";
import PropertiesDeletePage from "./pages/GlobalSettings/properties/PropertiesDeletePage";

export const history = createBrowserHistory();


function RequireAuth({children}: { children: JSX.Element }) {
    const userLogin = useSelector(state => state.userLogin)
    const loggedIn = userLogin.loggedIn

    //let location = useLocation();

    if (!loggedIn) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login"/>;
    }

    return children;
}

function RenderRow(props) {
    return (
        <TableRow key={props.label}>
            <TableCell>{props.label}</TableCell>
            <TableCell>{props.statusOk === undefined ? <ReportProblemOutlinedIcon/> : props.statusOk ?
                <DoneOutlineOutlinedIcon/> : <PendingOutlinedIcon/>}</TableCell>
        </TableRow>
    )
}

function PageLoadingScreen(props) {

    const configuration = useSelector(state => state.configuration)

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{minHeight: '100vh', backgroundImage: "url(/page_background.png)", backgroundSize: "cover"}}
        >
            <Grid item xs={3}>
                <CircularProgress/>
            </Grid>

            <Grid item xs={5}>
                {configuration.fullyLoaded ? "Finished loading configuration" : "Connecting to backend and loading configuration"}
            </Grid>

            <Grid item>
                <Table size={"small"}>
                    <TableBody>
                        <RenderRow label={"user access"} statusOk={configuration.done.userAccess}/>
                        <RenderRow label={"user settings"} statusOk={configuration.done.userSettings}/>
                        <RenderRow label={"properties"} statusOk={configuration.done.stockProperties}/>
                        <RenderRow label={"products"} statusOk={configuration.done.stockProducts}/>
                        <RenderRow label={"locations"} statusOk={configuration.done.stockLocations}/>
                        <RenderRow label={"devices"} statusOk={configuration.done.stockDevices}/>
                        <RenderRow label={"export"} statusOk={configuration.done.stockExport}/>
                    </TableBody>
                </Table>
            </Grid>

        </Grid>

    )
}

function MainPage() {
    return (

        <div style={{minHeight: '100vh', backgroundImage: "url(/page_background.png)", backgroundSize: "cover"}}>
            <Box sx={{display: "flex"}}>
                <SnackBar/>
                <PageHeader/>
                <LeftMenu/>

                <Box
                    component="main"
                    sx={{flexGrow: 1, p: 0, marginTop: "64px"}}
                >


                    <Routes>
                        <Route exact path='/' element={<DashBoardPage/>}/>

                        <Route path='/stock_devices' element={<StockDevicesPage/>}/>
                        <Route path="/stock_devices/details/:selectedDeviceId" element={<StockDeviceDetailsPage />}/>
                        <Route path="/stock_devices/edit/:selectedDeviceId" element={<StockDeviceEditPage />}/>
                        <Route path="/stock_devices/delete/:selectedDeviceId" element={<StockDeviceDeletePage />}/>

                        <Route path='/settings/products' element={<ProductsSettingsPage />}/>
                        <Route path='/settings/products/details/:selectedProductId' element={<ProductDetailsPage />}/>
                        <Route path='/settings/products/edit/:selectedProductId' element={<ProductEditPage />}/>
                        <Route path='/settings/products/delete/:selectedProductId' element={<ProductDeletePage />}/>

                        <Route path='/settings/properties' element={<PropertiesSettingsPage />}/>
                        <Route path='/settings/properties/details/:selectedPropertyId' element={<PropertiesDetailsPage />}/>
                        <Route path='/settings/properties/edit/:selectedPropertyId' element={<PropertiesEditPage />}/>
                        <Route path='/settings/properties/delete/:selectedPropertyId' element={<PropertiesDeletePage />}/>

                        <Route path='/settings/locations' element={<LocationsSettingsPage />}/>

                        <Route path='/settings/export' element={<ExportSettingsPage />}/>

                        <Route path='/about' element={<AboutPage/>}/>


                        <Route path='/login' element={<LoginPage/>}/>
                        <Route path='/logout' element={<LogoutPage/>}/>
                        <Route path='/register' element={<RegisterPage/>}/>

                        <Route path='/user_account' element={<RequireAuth> <UserAccountPage/> </RequireAuth>}/>
                        <Route path='/user_management' element={<RequireAuth> <UserManagementPage/> </RequireAuth>}/>

                    </Routes>


                </Box>

            </Box>
        </div>

    )
}

function AppLoader() {

    SnackBar()

    const fullyLoaded = useSelector(state => state.configuration.fullyLoaded)
    const userLoggedIn = useSelector(state => state.userLogin.loggedIn)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        // loading page effect
        console.log("appLoader started")

        dispatchToStore(userLoginRestartFromSession())

        // unloading page effect
        return () => {

            console.log("appLoader finished")
        }

    }, [])

    useEffect(() => {
        if (userLoggedIn) {
            console.info("user has logged in -- bootstrapping")
            dispatchToStore(configurationBootstrapApp())
        }

    }, [userLoggedIn])

    useEffect(() => {
        if (fullyLoaded) {
            console.info("configuration fully loaded")
            setLoading(false)
        } else {
            console.warn("connection lost")
            setLoading(true)
        }
    }, [fullyLoaded]);

    if (!userLoggedIn) {
        return <LoginPage/>
    }

    return (
        <>
            {loading ? <PageLoadingScreen/> : <MainPage/>}
        </>
    )
}

function App() {

    return (
        <Provider store={configureAppStore()}>
            <HashRouter>

                <SnackbarProvider>

                    <Routes>
                        /* route without login */
                        <Route path='/device_info/:device_serial' element={<DeviceInfo />}/>

                        /* enforce login here */
                        <Route exact path='/*' element={<AppLoader/>} />
                    </Routes>

                </SnackbarProvider>

            </HashRouter>
        </Provider>
    );
}

export default App;