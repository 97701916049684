import React from 'react';
import {theme} from "../../style/theme";
import Grid from "@mui/material/Grid";
import CustomCard from "../../components/generic/CustomCard";
import {AppVersion} from "../../components/About/Version";

export default function AboutPage(props) {

    return (
        <Grid container spacing={theme.spacing(2)} sx={{padding: theme.spacing(2)}}>
            <Grid item xs={12}>
                <CustomCard caption={"About"}>
                    <AppVersion />
                </CustomCard>
            </Grid>

        </Grid>
    )


}

