import {select, take, delay, race} from "redux-saga/effects";
import {dispatchToStore} from "../../store/configureStore";
import {snackbarEnqueue} from "../../store/slices/snackbar";
import {versionString} from "../About/Version";

export function isDebugEnvironment(){
    if (process.env.NODE_ENV !== 'production'){
        return true
    }
    return false
}

export function isDevEnvironment(){
    if (isDebugEnvironment()){
        return true
    }

    if (window.location.host.startsWith("dev-")){
        return true
    }

    return false
}

function localStorageKey(key){
    return versionString + "-" + key
}

export const localStorageSessionKey = localStorageKey("session")
export const localStorageDeviceHeaderKey = localStorageKey("deviceHeader")

export function formatFixedFloat(value, numberOfDigits) {
    if (!Number(value)) {
        return value
    }
    return Number(value).toFixed(numberOfDigits)
}


export function isEmpty(obj) {
    if (obj // 👈 null and undefined check
        && Object.keys(obj).length === 0
        && Object.getPrototypeOf(obj) === Object.prototype) {
        return true
    }
    return false
}

export function formatDateToStr(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours(),
        minutes = d.getMinutes(),
        seconds = d.getSeconds();


    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    if (hours.length < 2)
        hours = '0' + hours;
    if (minutes.length < 2)
        minutes = '0' + minutes;
    if (seconds.length < 2)
        seconds = '0' + seconds;

    return [year, month, day, hours, minutes, seconds].join('-');
}

const fileApplicationTypes = {
    json: "application/json",
    csv: "text/csv"
}

const fileExtension = {
    json: ".json",
    csv: ".csv"
}


// ex. fileType = "json"
export function downloadFile(filename, fileType, content) {

    if (fileApplicationTypes[fileType] === undefined) {
        console.error("unknown file type " + fileType)
        dispatchToStore(snackbarEnqueue({
            message: "unknown file type " + fileType,
            severity: "error"
        }))
        return
    }

    const fileApplicationType = fileApplicationTypes[fileType]

    const element = document.createElement("a");
    const file = new Blob([content], {
        type: fileApplicationType
    });
    element.href = URL.createObjectURL(file);

    // filename: ex. Matrix3x3_2022-05-04-11-34-47.json
    element.download = filename;
    document.body.appendChild(element);
    element.click();

}

export function downloadFileWithNewTimestamp(filename_base, fileType, content) {
    if (fileExtension[fileType] === undefined) {
        console.error("unknown file type " + fileType)
        dispatchToStore(snackbarEnqueue({
            message: "unknown file type " + fileType,
            severity: "error"
        }))
        return
    }

    // generate filename
    const extension = fileExtension[fileType]
    const timeStampStr = formatDateToStr(Date())
    const filename = filename_base + '_' + timeStampStr + extension

    downloadFile(filename, fileType, content)
}


export function uploadFileDialog(expectedFileType, onFileUploadSuccessCallback, onFileUploadFailedCallback) {

    if (fileApplicationTypes[expectedFileType] === undefined) {
        console.error("unknown file type " + expectedFileType)
        dispatchToStore(snackbarEnqueue({
            message: "Unknown file type " + expectedFileType,
            severity: "error"
        }))
        return
    }

    const clickElem = (elem) => {
        let eventMouse = document.createEvent("MouseEvents");
        eventMouse.initMouseEvent("click", true, false, window,
            0, 0, 0, 0, 0,
            false, false, false, false, 0, null);
        elem.dispatchEvent(eventMouse)
    };

    let readFile = (e) => {
        let file = e.target.files[0];
        if ((file === undefined) || (file === null)) {
            console.error("invalid file input")
            dispatchToStore(snackbarEnqueue({
                message: "invalid file input ",
                severity: "error"
            }))
            return;
        }

        let reader = new FileReader();
        reader.onload = (e) => {
            let content = e.target.result;
            fileInput.loadFromFile(file, content);
            document.body.removeChild(fileInput)
        };

        reader.readAsText(file)
    };

    let fileInput = document.createElement("input");
    fileInput.type = 'file';
    fileInput.style.display = 'none';
    fileInput.onchange = readFile;
    fileInput.loadFromFile = (file, content) => {

        if (file.type === fileApplicationTypes[expectedFileType]) {
            onFileUploadSuccessCallback(file, content)
        } else {
            onFileUploadFailedCallback()
        }

    }

    document.body.appendChild(fileInput);
    clickElem(fileInput);
}


export function* waitFor(expectedAction, selector, timeout_msec=-1) {
    // check initial condition
    if (yield select(selector)) {
        // return immediately, when condition is fulfilled
        return;
    }

    while (true) {
        // wait for the expectedAction
        if (timeout_msec > 0){
            // we are using the race API to let async events concur
            const {action, timeout} = yield race({
                action: take(expectedAction),
                timeout: delay(timeout_msec),
            })

            if (timeout) {
                console.warn("waitFor - timeout")
                return false
            }
        } else {
            // waiting without timeout
            yield take(expectedAction)
        }

        // check if the expected selector is fulfilled
        const result = yield select(selector)
        if (result) {
            // return if the condition is true after receiving the expected action
            return true;
        }
    }
}

/*
export function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}
*/

export function random(max){
    return Math.floor(Math.random() * max);
}