import React, {useEffect} from 'react';
import CustomCard from "../../../components/generic/CustomCard";
import {theme} from "../../../style/theme";
import Grid from "@mui/material/Grid";
import {dispatchToStore} from "../../../store/configureStore";
import {useSelector} from "react-redux";
import DatabasePreviewList from "../../../components/generic/DatabasePreviewList";
import {stockPropertiesLoad} from "../../../store/slices/stock/properties";


export default function PropertiesSettingsPage(props) {

    const properties = useSelector(store => store.stockProperties)

    useEffect(() => {
        // loading page effect
        dispatchToStore(stockPropertiesLoad({}))

        // unloading page effect
        return () => {

        }

    }, []);

    return (
        <Grid container spacing={theme.spacing(2)} sx={{padding: theme.spacing(2)}}>
            <Grid item xs={12}>
                <CustomCard caption={"Properties for Products"}>
                    <DatabasePreviewList
                        uniqueKey={properties.uniqueKey}
                        header={properties.header}
                        editAble={properties.editAble}
                        rows={properties.items}
                    />
                </CustomCard>
            </Grid>
        </Grid>
    )


}

