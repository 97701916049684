import React, {useEffect, useState} from 'react';
import CustomCard from "../../../components/generic/CustomCard";
import {theme} from "../../../style/theme";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {dispatchToStore} from "../../../store/configureStore";
import {useParams} from "react-router";
import {Button, Stack, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {Link as RouterLink} from "react-router-dom";
import {DisplayExpectedProps} from "../../../components/stock/products";
import {stockProductsLoad} from "../../../store/slices/stock/products";
import {stockPropertiesLoad} from "../../../store/slices/stock/properties";

export default function ProductDetailsPage() {

    const {selectedProductId} = useParams()

    const products = useSelector(store => store.stockProducts)

    const [loaded, setLoaded] = useState(false)
    const [product, setProduct] = useState({})
    const [expectedProps, setExpectedProps] = useState({})

    useEffect(() => {
        // loading page effect
        dispatchToStore(stockProductsLoad({}))
        dispatchToStore(stockPropertiesLoad({}))

        // unloading page effect
        return () => {

        }

    }, []);

    useEffect(() => {
        if (!products.loaded ){
            return
        }

        const selectedProduct = products.items[selectedProductId]
        setProduct(selectedProduct)

        setExpectedProps(selectedProduct.expected_props)

        setLoaded(true)
        return () => {

        }

    }, [product.loaded]);

    return (
        <Grid container spacing={theme.spacing(2)} sx={{padding: theme.spacing(2)}}>
            <Grid item xs={12}>
                <CustomCard caption={"View Product Details"}>
                    {!loaded ?
                        "loading"
                        :
                        <Stack direction={"column"} spacing={2}>

                            <TextField id={"name"} label={"Serial Number"} value={product["name"]} variant={"standard"}
                                        disabled={true} />

                            <TextField id={"desc"} label={"Product"} value={product["desc"]} variant={"standard"}
                                       disabled={true} />

                            <TextField id={"modified_at"} label={"Modified at"} value={product["modified_at"]}
                                       disabled={true} variant={"standard"}/>

                            <DisplayExpectedProps expectedProps={expectedProps}/>

                            <Stack direction={"row"} alignItems={"center"} gap={1} justifyContent={"center"}>


                                <Button size={"large"}
                                        color={"primary"}
                                        sx={{marginTop: 4}}
                                        component={RouterLink}
                                        variant={"outlined"}
                                        to={'/settings/products'}

                                        onClick={() => {

                                        }}>
                                    <CloseIcon sx={{marginRight: 2}}/>
                                    Close
                                </Button>


                            </Stack>
                        </Stack>
                    }
                </CustomCard>
            </Grid>

        </Grid>

    )


}

