import React, {useEffect, useState} from 'react';
import CustomCard from "../../../components/generic/CustomCard";
import {theme} from "../../../style/theme";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {dispatchToStore} from "../../../store/configureStore";
import {useParams} from "react-router";
import {Button, FormControl, InputLabel, Select, Stack, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {Link as RouterLink} from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import {stockPropertiesLoad, stockPropertiesUpsert} from "../../../store/slices/stock/properties";
import MenuItem from "@mui/material/MenuItem";


export default function PropertiesEditPage() {

    const {selectedPropertyId} = useParams()

    const propertyList = useSelector(store => store.stockProperties.items)
    const propertyTypes = useSelector(store => store.stockProperties.propertyTypes)

    useEffect(() => {
        // loading page effect
        dispatchToStore(stockPropertiesLoad({}))

        // unloading page effect
        return () => {

        }

    }, []);

    let defaultProperty = {}
    if (Number(selectedPropertyId) === -1){
        defaultProperty = {
            property_id: -1,
            desc: "new",
            type: Object.keys(propertyTypes)[0]
        }
    } else {
        defaultProperty = propertyList[selectedPropertyId]
    }

    const [property, setProperty] = useState(defaultProperty)

    // ---
    function onUpsertItemRow(row) {
        const newProperty = {
            property_id: row["property_id"],
            desc: row["desc"],
            type: row["type"],
        }
        dispatchToStore(stockPropertiesUpsert(newProperty))
    }

    function onChangeName(desc) {
        setProperty({...property, "desc": desc})
    }

    function onChangeType(type) {
        setProperty({...property, "type": type})
    }


    return (
        <Grid container spacing={theme.spacing(4)} sx={{padding: theme.spacing(2)}}>
            <Grid item xs={12}>
                <CustomCard caption={"Edit Product Details"}>
                    <Stack direction={"column"} spacing={2}>

                        <TextField id={"name"} label={"Name"} value={property["desc"]}
                                   onChange={(e) => onChangeName(e.target.value)}/>

                        <FormControl fullWidth>
                            <InputLabel id="property_select">Type</InputLabel>
                            <Select
                                value={property["type"]}
                                label={"property"}
                                onChange={(event) => {
                                    onChangeType(event.target.value)
                                }}

                            >

                                {Object.entries(propertyTypes).map(([id, value]) => {

                                    return (<MenuItem value={id} key={id}>{id}</MenuItem>)

                                    }
                                )}

                            </Select>
                        </FormControl>

                        <TextField id={"modified_at"} label={"Modified at"} value={property["modified_at"]}
                                    disabled={true}/>


                        <Stack direction={"row"} alignItems={"center"} gap={1} justifyContent={"center"}>

                            <Button size={"large"}
                                    color={"primary"}
                                    sx={{marginTop: 4}}
                                    component={RouterLink}
                                    variant={"outlined"}
                                    to={'/settings/properties'}

                                    onClick={() => {
                                        onUpsertItemRow(property)
                                    }}>
                                <SaveIcon sx={{marginRight: 2}}/>
                                Save
                            </Button>

                            <Button size={"large"}
                                    color={"primary"}
                                    sx={{marginTop: 4}}
                                    component={RouterLink}
                                    to={'/settings/properties'}

                                    onClick={() => {

                                    }}>
                                <CloseIcon sx={{marginRight: 2}}/>
                                Cancel
                            </Button>


                        </Stack>
                    </Stack>
                </CustomCard>
            </Grid>


        </Grid>

    )


}

