import axios from "axios";
import {select, call, put} from "redux-saga/effects";
import {api_version, getApiUrl} from "../../components/conf/configuration";
import {snackbarEnqueue} from "../slices/snackbar";

const API_RESULT_SUCCESS = "SUCCESS"
const API_RESULT_FAILED = "FAILED"

function apiLog(apiRequestData, apiResponse){
    console.group("apiRequest")
    console.log("request", apiRequestData)
    console.log("response", apiResponse)
    console.groupEnd()
}

function apiError(apiRequestData, apiResponse){
    console.group("apiRequest")
    console.log("request", apiRequestData)
    console.error("response", apiResponse)
    console.groupEnd()
}

export function* apiRequest(type, action, data) {

    const auth = yield select(state => state.apiConnector.auth)

    const apiRequestData = {
        API_VERSION: api_version,

        type: type,
        action: action,

        auth: auth,

        data: data,
    }

    // initialize response, to have proper format in all cases
    let apiResponseOk = false

    let apiResponseData = {
        API_VERSION: api_version,
        data: [],
        info: "unknown error",
        result: API_RESULT_FAILED,
    }

    try {

        const response = yield call(axios.post, getApiUrl(), apiRequestData)

        // response code of POST should be 200
        if (response.status === 200) {
            // response ok
            apiResponseData = response.data
            apiResponseOk = true
        }

    } catch (e) {
        apiResponseData = e.response.data
        apiResponseOk = false

        // service unavailable
        if (e.response.status === 503) {
            yield put(snackbarEnqueue({
                message: "api service unavailable",
                severity: "error"
            }))
        }
    }


    // validate response
    if (apiResponseData.API_VERSION !== api_version){
        apiError(apiRequestData, apiResponseData)
        apiResponseOk = false
    }
    if (apiResponseData.result !== API_RESULT_SUCCESS){
        apiResponseOk = false
    }

    // log the result
    if (apiResponseOk){
        apiLog(apiRequestData, apiResponseData)
    } else {
        apiError(apiRequestData, apiResponseData)
    }

    return [apiResponseData, apiResponseOk]
}
