import React from 'react';
import {useSelector} from "react-redux";
import {Button, Card, CardContent, Container, Grid, TextField,} from "@mui/material";

export default function UserAccountPage() {

    const userLogin = useSelector(state => state.userLogin)

    return (
        <Container maxWidth="md" sx={{padding: 4, marginTop: 10}}>
            <Card>
                <CardContent>
                    <h1>My Account</h1>

                    <Grid container direction={'column'} spacing={2} justifyContent={'center'} alignItems="center">

                        <Grid item>
                            <TextField
                                disabled
                                id="user"
                                label="user"
                                defaultValue={userLogin.user}
                            />
                        </Grid>

                        <Grid item>
                            <TextField
                                disabled
                                type="password"
                                id="password"
                                label="password"
                                defaultValue=""
                            />
                        </Grid>

                        <Grid item>
                            <TextField
                                disabled
                                type="password"
                                id="password_confirm"
                                label="password_confirm"
                                defaultValue=""
                            />
                        </Grid>

                        <Grid item>
                            <Button variant={"contained"} color={"primary"} disabled>Apply</Button>
                            <Button variant={"contained"} color={"secondary"} disabled>Cancel</Button>
                        </Grid>

                    </Grid>

                </CardContent>
            </Card>
        </Container>
    );

};

