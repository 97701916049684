import {createSlice} from '@reduxjs/toolkit'


export const stockLocationsSlice = createSlice({
    name: 'stockLocations',
    initialState: {
        loaded: false,

        uniqueKey: "location_id",

        header: {
            name: "Location Name",
            desc: "Location Description",
            modified_at: "Modified at"
        },

        editAble: {
            name: true,
            desc: true,
            modified_at: false,
        },

        items: {}
    },

    reducers: {
        stockLocationsLoad: (state, {payload}) => {
        },

        stockLocationsLoaded: (state, {payload}) => {
            // ---
            // we prefer to overwrite items instead of clear and re-create

            // mark all
            const deleteItems = {}
            Object.entries(state.items).forEach(([location_id, value]) =>{
                deleteItems[location_id] = true
            })

            // overwrite
            Object.entries(payload.items).forEach(([location_id, value])=>{
                if (state.items[location_id] === undefined){
                    state.items[location_id] = {}
                }

                state.items[location_id]["location_id"] = location_id
                state.items[location_id]["name"] = value.name
                state.items[location_id]["desc"] = value.desc
                state.items[location_id]["modified_at"] = value.modified_at
                state.items[location_id]["archive"] = value.archive

                deleteItems[location_id] = false
            })

            // clean the left-overs
            Object.entries(deleteItems).forEach(([id, value]) =>{
                if (value){
                    delete state.items[id]
                }
            })

            state.loaded = true
        },

        stockLocationsUpsert: (state, {payload}) => {

        },

        stockLocationsDelete: (state, {payload}) => {

        }

    }
})

export const {
    stockLocationsLoad,
    stockLocationsLoaded,
    stockLocationsUpsert,
    stockLocationsDelete,

} = stockLocationsSlice.actions

export default stockLocationsSlice.reducer
