import React, {useEffect} from 'react';
import CustomCard from "../../components/generic/CustomCard";
import {theme} from "../../style/theme";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {dispatchToStore} from "../../store/configureStore";
import {stockDevicesLoad} from "../../store/slices/stock/devices";
import {useParams} from "react-router";
import {Button, Stack, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {Link as RouterLink} from "react-router-dom";
import {DisplayDeviceProps, DisplayQrCode} from "../../components/stock/item";
import Box from "@mui/material/Box";

export default function StockDeviceDetailsPage() {

    const {selectedDeviceId} = useParams()

    const devices = useSelector(store => store.stockDevices.items)
    const locations = useSelector(store => store.stockLocations.items)
    const products = useSelector(store => store.stockProducts.items)

    const dbItem = devices[selectedDeviceId]
    const device = {...dbItem}
    device.product_name = products[dbItem["product_id"]]["name"]
    device.location_name = locations[dbItem["location_id"]]["name"]

    useEffect(() => {
        // loading page effect
        dispatchToStore(stockDevicesLoad({}))

        // unloading page effect
        return () => {

        }

    }, []);

    const expectedProperties = products[device.product_id].expected_props
    const actualProps = device.props

    return (
        <Grid container spacing={theme.spacing(2)} sx={{padding: theme.spacing(2)}}>
            <Grid item xs={12}>
                <CustomCard caption={"View Device Details [" + device["serial"] + "]"}>
                    <Stack direction={"column"} spacing={2}>

                        <Stack direction={"row"} spacing={2}>
                            <Box sx={{width: "100%"}}>
                                <Stack direction={"column"} spacing={2}>
                                    <TextField id={"product"} label={"Product"} value={device["product_name"]}
                                               disabled={true}/>

                                    <TextField id={"location"} label={"Location"} value={device["location_name"]}
                                               disabled={true}/>

                                    <TextField id={"modified_at"} label={"Modified at"} value={device["modified_at"]}
                                               disabled={true}/>

                                    <TextField id={"created_at"} label={"Created at"} value={device["created_at"]}
                                               disabled={true}/>
                                </Stack>
                            </Box>

                            <DisplayQrCode serial={device["serial"]}/>

                        </Stack>

                        <DisplayDeviceProps id={"props"} expectedProperties={expectedProperties} actualProps={actualProps} />

                        <Stack direction={"row"} alignItems={"center"} gap={1} justifyContent={"center"}>


                            <Button size={"large"}
                                    color={"primary"}
                                    sx={{marginTop: 4}}
                                    component={RouterLink}
                                    to={'/stock_devices'}
                                    variant={"outlined"}
                            >
                                <CloseIcon sx={{marginRight: 2}}/>
                                Close
                            </Button>


                        </Stack>
                    </Stack>
                </CustomCard>
            </Grid>


        </Grid>

    )


}

