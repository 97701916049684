import {useEffect} from 'react';
import CustomCard from "../../components/generic/CustomCard";
import Grid from "@mui/material/Grid";
import {theme} from "../../style/theme";
import {useSelector} from "react-redux";
import {dispatchToStore} from "../../store/configureStore";
import {stockDevicesLoad} from "../../store/slices/stock/devices";
import {stockProductsLoad} from "../../store/slices/stock/products";
import {stockLocationsLoad} from "../../store/slices/stock/locations";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

export default function DashBoardPage() {

    const devices = useSelector(store => store.stockDevices)
    const locations = useSelector(store => store.stockLocations)
    const products = useSelector(store => store.stockProducts)


    useEffect(() => {
        // loading page effect
        console.log("load dashboard")

        // loading page effect
        dispatchToStore(stockDevicesLoad({}))
        dispatchToStore(stockProductsLoad({}))
        dispatchToStore(stockLocationsLoad({}))

        // unloading page effect
        return () => {
            console.log("unloaded dashboard")
        }

    }, []);

    const renderedLocations = []
    Object.entries(locations.items).forEach(([location_id, location]) => {

        if (location.archive) {
            return
        }

        const listedProducts = {}

        Object.entries(devices.items).forEach(([device_id, device])=>{

            if (device.location_id !== Number(location_id)){
                return
            }

            if (device.archive === 0){
                if (listedProducts[device.product_id] === undefined){
                    listedProducts[device.product_id] = 0
                }

                listedProducts[device.product_id] += 1
            }

        })

        const renderedProducts = []
        Object.entries(listedProducts).forEach(([product_id, count])=>{
            renderedProducts.push(<TableRow key={product_id}>
                    <TableCell>{products.items[product_id].name}</TableCell>
                    <TableCell>{count}</TableCell>
                </TableRow>)
        })

        if (Object.keys(listedProducts).length > 0) {
            renderedLocations.push(<Grid item xs={6} key={location_id}>
                <CustomCard key={location_id} caption={location.name + " (" + location.desc + ")"}>
                    <Table size="small">
                        <TableHead>
                            <TableRow sx={{background: "lightgray"}}>
                                <TableCell>Product</TableCell>
                                <TableCell>Stock Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderedProducts}
                        </TableBody>
                    </Table>

                </CustomCard>
            </Grid>)
        }
    })

    return (
        <Grid container spacing={theme.spacing(2)} sx={{padding: theme.spacing(2)}}>

            {renderedLocations}

        </Grid>
    );
};
