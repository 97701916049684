import * as React from "react";
import {createRoot} from 'react-dom/client'

import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';

import {theme} from './style/theme'
import App from './App';


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(

    <ThemeProvider theme={theme}>

        <CssBaseline/>

        <App/>

    </ThemeProvider>

);