import Typography from "@mui/material/Typography";
import {isDevEnvironment} from "../generic/utils";


const releaseType = isDevEnvironment() ? "-dev" : ""

export const versionNumber = "v0.2.4" + releaseType
export const versionString = "warehouseApp " + versionNumber

export function AppVersion(){
    return (
        <Typography>
            {versionString}
        </Typography>
    )
}