import {createSlice} from '@reduxjs/toolkit'


export const stockDevicesSlice = createSlice({
    name: 'stockDevices',
    initialState: {
        loaded: false,

        uniqueKey: "device_id",

        items: undefined,

        publicDeviceInfo: {
            device: null,
        }
    },

    reducers: {
        stockDevicesLoad: (state, {payload}) => {
            // state.items = []
            state.loaded = false
        },

        stockDevicesLoaded: (state, {payload}) => {
            // ---
            // we prefer to overwrite items instead of clear and re-create

            if (state.items === undefined){
                state.items = {}
            }

            // mark all
            const deleteItems = {}
            Object.entries(state.items).forEach(([device_id, value]) =>{
                deleteItems[device_id] = true
            })

            // overwrite
            Object.entries(payload.devices).forEach(([device_id, value])=>{
                if (state.items[device_id] === undefined){
                    state.items[device_id] = {
                        device_id: -1,
                        serial: "",
                        product_id: -1,
                        location_id: -1,
                        modified_at: "",
                        created_at: "",
                        archive: false,
                        props: undefined
                    }
                }

                state.items[device_id]["device_id"] = Number(device_id)
                state.items[device_id]["serial"] = value.serial
                state.items[device_id]["product_id"] = value.product_id
                state.items[device_id]["location_id"] = value.location_id
                state.items[device_id]["modified_at"] = value.modified_at
                state.items[device_id]["created_at"] = value.created_at
                state.items[device_id]["archive"] = value.archive

                state.items[device_id]["props"] = {}

                Object.entries(value.props).forEach(([prop_id, item])=>{
                    state.items[device_id]["props"][prop_id] = item.value
                })

                // add generic fields for common/homogeneous property filtering
                state.items[device_id]["props"]["1"] = Number(device_id)
                state.items[device_id]["props"]["2"] = value.serial
                state.items[device_id]["props"]["3"] = value.product_id
                state.items[device_id]["props"]["4"] = value.location_id

                deleteItems[device_id] = false
            })

            // clean the left-overs
            Object.entries(deleteItems).forEach(([device_id, value]) =>{
                if (value){
                    delete state.items[device_id]
                }
            })

            if (state.items !== undefined){
                state.loaded = true
            }
        },

        stockDevicesUpsert: (state, {payload}) => {

        },

        stockDevicesDelete: (state, {payload}) => {

        },

        stockDevicesPublicInfoLoad: (state, {payload}) => {

        },

        stockDevicesPublicInfoLoaded: (state, {payload}) => {
            state.publicDeviceInfo.device = payload.device
        },

    }
})

export const {
    stockDevicesLoad,
    stockDevicesLoaded,

    stockDevicesUpsert,
    stockDevicesDelete,

    stockDevicesPublicInfoLoad,
    stockDevicesPublicInfoLoaded,

} = stockDevicesSlice.actions

export default stockDevicesSlice.reducer

