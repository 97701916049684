import {createSlice} from '@reduxjs/toolkit'


export const stockExportSlice = createSlice({
    name: 'stockExport',
    initialState: {
        loaded: false,

        items: {}
    },

    reducers: {
        stockExportLoad: (state, {payload}) => {
        },

        stockExportLoaded: (state, {payload}) => {
            state.items = payload.items
            state.loaded = true
        },

    }
})

export const {
    stockExportLoad,
    stockExportLoaded,


} = stockExportSlice.actions

export default stockExportSlice.reducer
