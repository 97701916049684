import {put} from "redux-saga/effects";
import {userSettingsInitialize,} from "../slices/userSettings";
import {configurationSetDoneStatus} from "../slices/configuration";

export function* initializeUserSettings(action) {

    yield put(configurationSetDoneStatus({name: "userSettings", done: false}))

    // clear
    yield put(userSettingsInitialize())

    // \TODO
    // ...


    yield put(configurationSetDoneStatus({name: "userSettings", done: true}))
}

