import React, {useEffect, useState} from 'react';
import CustomCard from "../../components/generic/CustomCard";
import {theme} from "../../style/theme";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {dispatchToStore} from "../../store/configureStore";
import {stockProductsLoad} from "../../store/slices/stock/products";
import {stockLocationsLoad} from "../../store/slices/stock/locations";
import {stockDevicesLoad} from "../../store/slices/stock/devices";
import DatabasePreviewList from "../../components/generic/DatabasePreviewList";
import {Accordion, AccordionDetails, AccordionSummary, Stack} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from "@mui/material/Chip";
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import {localStorageDeviceHeaderKey} from "../../components/generic/utils";
import {stockPropertiesLoad} from "../../store/slices/stock/properties";

function TableHeaders(props){
    const active = props.active
    const available = props.available
    const setHeader = props.setHeader

    function onDelete(x){
        const a = {...active}
        delete a[x]
        setHeader(a)
    }

    function onAdd(id, item){
        const a = {...active}
        a[id] = item
        setHeader(a)
    }

    const exclusiveAvailable = []
    Object.entries(available).forEach(([id, item])=>{
        if (active[id] === undefined){
            exclusiveAvailable.push(
                <Grid item key={id}>
                <Chip label={item.desc} variant={"outlined"} color={"primary"}
                     onClick={()=>onAdd(id, item)}/>
                </Grid>
            )
        }
    })
    return (
            <Grid container spacing={theme.spacing(2)}>
                <Grid item>
                    <Grid container spacing={theme.spacing(2)}>
                    {Object.entries(active).map(([id, item]) => {
                        return (
                            <Grid item key={id}>
                            <Chip label={item.desc} color={"primary"}
                                       onDelete={()=>onDelete(id)}/>
                            </Grid>
                            )
                    })}
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container spacing={theme.spacing(2)}>
                        {exclusiveAvailable}
                    </Grid>
                </Grid>
            </Grid>
    )
}

export default function StockDevicesPage(props) {

    const devices = useSelector(store => store.stockDevices)
    const locations = useSelector(store => store.stockLocations)
    const products = useSelector(store => store.stockProducts)
    const properties = useSelector(store => store.stockProperties)

    const [activeHeader, setActiveHeader] = useState({})
    const [availableHeader, setAvailableHeader] = useState({})

    const [rows, setRows] = useState([])
    const [pageLoaded, setPageLoaded] = useState(false)

    useEffect(() => {
        // loading page effect
        dispatchToStore(stockProductsLoad({}))
        dispatchToStore(stockLocationsLoad({}))
        dispatchToStore(stockPropertiesLoad({}))
        dispatchToStore(stockDevicesLoad({}))

        // unloading page effect
        return () => {

        }

    }, []);

    useEffect(() => {
        // loading page effect
        if (!devices.loaded || !locations.loaded || !products.loaded || !properties.loaded){
            return
        }
        const allRows = []

        // ---
        Object.entries(devices.items).forEach(([id, device])=>{
            if (device.archive === 0) {
                const row = {...device.props}

                // lookup
                row[3] = products.items[device.product_id]["name"]
                row[4] = locations.items[device.location_id]["name"]

                allRows.push(row)
            }
        })

        setRows(allRows)

        const available = {...properties.items}
        setAvailableHeader(available)

        const storageHeaders = JSON.parse(localStorage.getItem(localStorageDeviceHeaderKey))

        if (storageHeaders !== null){
            setActiveHeader(storageHeaders)
        } else {
            const defaultHeaders = {
                1: properties.items[1],
                2: properties.items[2],
            }
            setActiveHeader(defaultHeaders)
        }

        setPageLoaded(true)
        // unloading page effect
        return () => {

        }

    }, [devices.loaded, locations.loaded, products.loaded, properties.loaded])

    function updateHeader(newHeader){
        setActiveHeader(newHeader)
        localStorage.setItem(localStorageDeviceHeaderKey, JSON.stringify(newHeader))
    }

    return (
        <Grid container spacing={theme.spacing(2)} sx={{padding: theme.spacing(2)}}>
            <Grid item xs={12}>
                <CustomCard caption={"Devices on Stock"}>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <ViewWeekIcon/>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Stack direction={"column"}>
                                    <TableHeaders active={activeHeader} available={availableHeader} setHeader={updateHeader}/>
                                </Stack>

                            </AccordionDetails>
                        </Accordion>


                    {pageLoaded ?
                        <DatabasePreviewList
                            uniqueKey={1}
                            header={activeHeader}
                            editAble={devices.editAble}
                            rows={rows}
                        />
                        :
                        "loading"
                    }
                </CustomCard>
            </Grid>


        </Grid>

    )


}

