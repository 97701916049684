import {createSlice} from '@reduxjs/toolkit'


export const stockProductsSlice = createSlice({
    name: 'stockProducts',
    initialState: {
        loaded: false,

        uniqueKey: "product_id",

        header: {
            name: {name: "Product Name", desc: "Product Name"},
            desc: {name: "Product Description", desc: "Product Description"},
        },

        editAble: {
            name: true,
            desc: true,
            modified_at: false,
        },

        items: {}
    },

    reducers: {
        stockProductsLoad: (state, {payload}) => {
        },

        stockProductsLoaded: (state, {payload}) => {
            // ---
            // we prefer to overwrite items instead of clear and re-create

            // mark all
            const deleteItems = {}
            Object.entries(state.items).forEach(([product_id, value]) =>{
                deleteItems[product_id] = true
            })

            // overwrite
            Object.entries(payload.items).forEach(([product_id, value])=>{
                if (state.items[product_id] === undefined){
                    state.items[product_id] = {}
                }

                state.items[product_id]["product_id"] = product_id
                state.items[product_id]["name"] = value.name
                state.items[product_id]["desc"] = value.desc
                state.items[product_id]["expected_props"] = value.expected_props !== null ? value.expected_props : {}
                state.items[product_id]["modified_at"] = value.modified_at
                state.items[product_id]["archive"] = value.archive
                
                deleteItems[product_id] = false
            })

            // clean the left-overs
            Object.entries(deleteItems).forEach(([id, value]) =>{
                if (value){
                    delete state.items[id]
                }
            })

            state.loaded = true
        },

        stockProductsUpsert: (state, {payload}) => {

        },

        stockProductsDelete: (state, {payload}) => {

        }

    }
})

export const {
    stockProductsLoad,
    stockProductsLoaded,
    stockProductsUpsert,
    stockProductsDelete,

} = stockProductsSlice.actions

export default stockProductsSlice.reducer
