import React, {useEffect, useState} from 'react';
import CustomCard from "../../components/generic/CustomCard";
import {theme} from "../../style/theme";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {dispatchToStore} from "../../store/configureStore";
import {useParams} from "react-router";
import {Button, Stack, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {Link as RouterLink} from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import {DisplayDeviceProps} from "../../components/stock/item";
import {stockDevicesDelete, stockDevicesLoad} from "../../store/slices/stock/devices";

export default function StockDeviceDeletePage() {

    const {selectedDeviceId} = useParams()

    const devices = useSelector(store => store.stockDevices)
    const locations = useSelector(store => store.stockLocations.items)
    const products = useSelector(store => store.stockProducts.items)

    const [device, setDevice] = useState({})
    const [expectedProperties, setExpectedProperties] = useState({})

    //device.product_name = products[dbItem["product_id"]]["name"]
    //device.location_name = locations[dbItem["location_id"]]["name"]

    useEffect(() => {
        // loading page effect
        dispatchToStore(stockDevicesLoad({}))

        // start with empty device
        setDevice({
            device_id: -1,
            serial: -1,
            product_id: -1,
            location_id: -1,
            props: {}
        })

        // unloading page effect
        return () => {

        }

    }, []);

    useEffect(() => {
        if (devices.loaded){
            const d = devices.items[selectedDeviceId]
            setDevice({
                ...d,
                product_name: products[d["product_id"]]["name"],
                location_name: locations[d["location_id"]]["name"]
            })
            setExpectedProperties(products[d.product_id].expected_props)
        }

        // unloading page effect
        return () => {

        }

    }, [devices.loaded]);

    // ---
    function onDeleteItemRow(row) {
        dispatchToStore(stockDevicesDelete({
            device_id: row["device_id"],
            serial: row["serial"],
            product_id: row["product_id"],
            location_id: row["location_id"],
        }))
    }

    return (
        <Grid container spacing={theme.spacing(4)} sx={{padding: theme.spacing(2)}}>
            <Grid item xs={12}>
                <CustomCard caption={"Delete Device [" + device["serial"] + "]"}>
                    {!devices.loaded ?
                        "loading..."
                    :

                    <Stack direction={"column"} spacing={2}>

                        <TextField id={"product"} label={"Product"} value={device["product_name"]} disabled={true}/>

                        <TextField id={"location"} label={"Location"} value={device["location_name"]}  disabled={true}/>

                        <TextField id={"modified_at"} label={"Modified at"} value={device["modified_at"]}  disabled={true}/>

                        <TextField id={"created_at"} label={"Created at"} value={device["created_at"]}  disabled={true}/>

                        <DisplayDeviceProps id={"props"} expectedProperties={expectedProperties} actualProps={device["props"]}/>

                        <Stack direction={"row"} alignItems={"center"} gap={1} justifyContent={"center"}>

                            <Button size={"large"}
                                    color={"primary"}
                                    sx={{marginTop: 4}}
                                    component={RouterLink}
                                    to={'/stock_devices'}

                                    onClick={() => {
                                        onDeleteItemRow(device)
                                    }}>
                                <SaveIcon sx={{marginRight: 2}}/>
                                Delete
                            </Button>

                            <Button size={"large"}
                                    color={"primary"}
                                    sx={{marginTop: 4}}
                                    component={RouterLink}
                                    to={'/stock_devices'}
                                    variant={"outlined"}
                                    >
                                <CloseIcon sx={{marginRight: 2}}/>
                                Cancel
                            </Button>


                        </Stack>
                    </Stack>
                    }
                </CustomCard>
            </Grid>


        </Grid>

    )


}

