import {createSlice} from '@reduxjs/toolkit'


export const stockPropertiesSlice = createSlice({
    name: 'stockProperties',
    initialState: {
        loaded: false,

        uniqueKey: "property_id",

        propertyTypes: undefined,

        header: {
            property_id: {name: "property_id", desc: "Property Id"},
            desc: {name: "desc", desc:"Property Description"},
            type: {name: "type", desc:"Property Type"},
            read_only: {name: "read_only", desc:"Read Only"},
        },

        editAble: {
            desc: true,
            modified_at: false,
        },

        items: {}
    },

    reducers: {
        stockPropertiesLoad: (state, {payload}) => {
        },

        stockPropertyTypesLoaded: (state, {payload}) => {
            state.propertyTypes = payload.propertyTypes
        },

        stockPropertiesLoaded: (state, {payload}) => {
            // ---
            // we prefer to overwrite items instead of clear and re-create

            // mark all
            const deleteItems = {}
            Object.entries(state.items).forEach(([property_id, value]) =>{
                deleteItems[property_id] = true
            })

            // overwrite
            Object.entries(payload.items).forEach(([property_id, value])=>{
                if (state.items[property_id] === undefined){
                    state.items[property_id] = {}
                }

                state.items[property_id]["property_id"] = property_id
                //state.items[property_id]["name"] = value.name
                state.items[property_id]["desc"] = value.desc
                state.items[property_id]["type"] = value.type
                state.items[property_id]["read_only"] = value.read_only
                state.items[property_id]["modified_at"] = value.modified_at
                state.items[property_id]["archive"] = value.archive

                deleteItems[property_id] = false
            })

            // clean the left-overs
            Object.entries(deleteItems).forEach(([id, value]) =>{
                if (value){
                    delete state.items[id]
                }
            })

            state.loaded = true
        },

        stockPropertiesUpsert: (state, {payload}) => {

        },

        stockPropertiesDelete: (state, {payload}) => {

        }

    }
})

export const {
    stockPropertiesLoad,
    stockPropertyTypesLoaded,
    stockPropertiesLoaded,
    stockPropertiesUpsert,
    stockPropertiesDelete,

} = stockPropertiesSlice.actions

export default stockPropertiesSlice.reducer
