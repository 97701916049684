import {put, delay} from "redux-saga/effects";

import {
    configurationReady,
} from "../slices/configuration";

import {initializeUserSettings} from "./userSettings";
import {initializeUserAccess} from "./userLogin";
import {initializeProducts} from "./stock/products";
import {initializeLocations} from "./stock/locations";
import {initializeStockDevices} from "./stock/devices";
import {initializeExport} from "./stock/export";
import {initializeProperties} from "./stock/properties";


export function* bootstrapApp(action) {

    // ... do something else

    yield startConfiguration()

}

export function* startConfiguration(action){

    const slowDown = false

    // start initialization procedure
    if (slowDown){
        yield delay(1000)
    }

    yield initializeUserAccess()
    if (slowDown){
        yield delay(1000)
    }
    yield initializeUserSettings()
    if (slowDown){
        yield delay(1000)
    }

    yield initializeProperties();
    if (slowDown){
        yield delay(1000)
    }

    yield initializeProducts()
    if (slowDown){
        yield delay(1000)
    }

    yield initializeLocations()
    if (slowDown){
        yield delay(1000)
    }

    yield initializeStockDevices();
    if (slowDown){
        yield delay(1000)
    }

    yield initializeExport();
    if (slowDown){
        yield delay(1000)
    }

    // finally mark ready
    yield put(configurationReady({}))

}
