import React, {useEffect} from 'react';
import CustomCard from "../../../components/generic/CustomCard";
import {theme} from "../../../style/theme";
import Grid from "@mui/material/Grid";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import {useSelector} from "react-redux";
import {dispatchToStore} from "../../../store/configureStore";
import {stockExportLoad} from "../../../store/slices/stock/export";
import {Stack} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function ExportSettingsPage(props) {

    const stockExport = useSelector(store => store.stockExport)

    useEffect(() => {
        // loading page effect
        dispatchToStore(stockExportLoad({}))

        // unloading page effect
        return () => {

        }

    }, []);

    const handleExport = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const fileName = "test excel";

        // Create a new workbook
        const wb = XLSX.utils.book_new();

        Object.entries(stockExport.items).forEach( ([product, item]) => {
            console.debug(product, item)
            const ws = XLSX.utils.json_to_sheet(item.list)

            XLSX.utils.book_append_sheet(wb, ws, product);
        })

        // Save the workbook as a blob
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });


        const blobData = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(blobData, fileName + fileExtension);

    };

    return (
        <Grid container spacing={theme.spacing(2)} sx={{padding: theme.spacing(2)}}>

            <Grid item xs={12}>
                <CustomCard caption={"Export"}>

                    {stockExport.loaded ?
                        <button onClick={handleExport}>Export to Excel</button>
                        :
                        <Stack direction={"row"}> <CircularProgress /> "Please Wait" </Stack>
                    }

                </CustomCard>
            </Grid>

        </Grid>
    )


}

