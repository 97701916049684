import {all, takeEvery} from 'redux-saga/effects'

import {userLoginRestartFromSession, userLoginStart} from '../slices/userLogin'
import {checkUserLogin, restartFromSessionUserLogin} from './userLogin'

import {
} from "../slices/userSettings";
import {
    bootstrapApp,
} from "./configuration";

import {configurationBootstrapApp} from "../slices/configuration";
import {deleteStockProduct, loadStockProducts, upsertStockProduct} from "./stock/products";
import {stockProductsDelete, stockProductsLoad, stockProductsUpsert} from "../slices/stock/products";
import {stockLocationsDelete, stockLocationsLoad, stockLocationsUpsert} from "../slices/stock/locations";
import {deleteStockLocation, loadStockLocations, upsertStockLocation} from "./stock/locations";
import {
    stockDevicesDelete,
    stockDevicesLoad,
    stockDevicesUpsert, stockDevicesPublicInfoLoad
} from "../slices/stock/devices";
import {
    deleteStockDevices,
    loadPublicDeviceInfo,
    loadStockDevices,
    upsertStockDevices
} from "./stock/devices";
import {stockExportLoad} from "../slices/stock/export";
import {loadStockExport} from "./stock/export";
import {stockPropertiesDelete, stockPropertiesLoad, stockPropertiesUpsert} from "../slices/stock/properties";
import {deleteStockProperties, loadStockProperties, upsertStockProperties} from "./stock/properties";

export default function* rootSaga() {
    return yield all([

        takeEvery(userLoginStart.type, checkUserLogin),
        takeEvery(userLoginRestartFromSession.type, restartFromSessionUserLogin),

        takeEvery(configurationBootstrapApp.type, bootstrapApp),

        takeEvery(stockDevicesLoad.type, loadStockDevices),
        takeEvery(stockDevicesUpsert.type, upsertStockDevices),
        takeEvery(stockDevicesDelete.type, deleteStockDevices),

        takeEvery(stockDevicesPublicInfoLoad.type, loadPublicDeviceInfo),

        takeEvery(stockProductsLoad.type, loadStockProducts),
        takeEvery(stockProductsUpsert.type, upsertStockProduct),
        takeEvery(stockProductsDelete.type, deleteStockProduct),

        takeEvery(stockLocationsLoad.type, loadStockLocations),
        takeEvery(stockLocationsUpsert.type, upsertStockLocation),
        takeEvery(stockLocationsDelete.type, deleteStockLocation),

        takeEvery(stockPropertiesLoad.type, loadStockProperties),
        takeEvery(stockPropertiesUpsert.type, upsertStockProperties),
        takeEvery(stockPropertiesDelete.type, deleteStockProperties),

        takeEvery(stockExportLoad.type, loadStockExport),
    ])
}