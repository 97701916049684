import {isDevEnvironment} from "../components/generic/utils";

export const globalStyles = {

    appBar: {
        zIndex: (theme) => theme.zIndex.drawer + 1,
    },

    toolbar: {
        background: !isDevEnvironment() ? "#171717" : "linear-gradient(to right , #171717, #6B0AC9)", //(theme) => theme.palette.secondary.main,
        foreground: (theme) => theme.palette.primary.main,
        padding: (theme) => theme.spacing(1),
        justifyContent: 'space-between'
    },

    appMenu: {
        drawerWidth: 240,
        drawerClosedWidth: 0,

    }
}