import React, {useEffect} from 'react';
import CustomCard from "../../../components/generic/CustomCard";
import {theme} from "../../../style/theme";
import Grid from "@mui/material/Grid";
import {dispatchToStore} from "../../../store/configureStore";
import {stockProductsLoad} from "../../../store/slices/stock/products";
import {useSelector} from "react-redux";
import DatabasePreviewList from "../../../components/generic/DatabasePreviewList";


export default function ProductsSettingsPage(props) {

    const products = useSelector(store => store.stockProducts)

    useEffect(() => {
        // loading page effect
        dispatchToStore(stockProductsLoad({}))

        // unloading page effect
        return () => {

        }

    }, []);

    return (
        <Grid container spacing={theme.spacing(2)} sx={{padding: theme.spacing(2)}}>
            <Grid item xs={12}>
                <CustomCard caption={"Products"}>
                    <DatabasePreviewList
                        uniqueKey={products.uniqueKey}
                        header={products.header}
                        editAble={products.editAble}
                        rows={products.items}
                    />
                </CustomCard>
            </Grid>
        </Grid>
    )


}

