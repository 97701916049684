import {Button, Stack, TextField} from "@mui/material";
import {theme} from "../../style/theme";
import React from "react";
import QRCode from "qrcode.react";
import jsPDF from "jspdf";
import PrintIcon from '@mui/icons-material/Print';
import {getHostingUrl, qr_code_size_mm} from "../conf/configuration";
import {useSelector} from "react-redux";

export function DisplayDeviceProps(props) {
    const actualProps = props.actualProps
    const expectedProperties = props.expectedProperties

    const properties = useSelector(store => store.stockProperties.items)

    const infoLines = []

    Object.entries(expectedProperties).forEach(([property_id, property]) => {
        if (!property["archive"]){
            const name = properties[property_id]["desc"]
            const actualValue = actualProps[property_id] === undefined ? "" : actualProps[property_id]

            infoLines.push(<TextField key={property_id} id={property_id} label={name}
                                      value={actualValue} disabled={true}/>)
        }
    })

    return (
        <Stack direction={"column"} spacing={theme.spacing(2)}>
            {infoLines}
        </Stack>

    )
}

export function EditDeviceProps(props) {
    const actualProps = props.actualProps
    const expectedProperties = props.expectedProperties

    const properties = useSelector(store => store.stockProperties.items)

    const onHandleChange = props.onHandleChange

    const infoLines = []

    //
    Object.entries(expectedProperties).forEach(([property_id, property]) => {
        if (!property["archive"]) {
            const name = properties[property_id]["desc"]
            const readOnly= Boolean(properties[property_id]["read_only"])
            const actualValue = actualProps[property_id] === undefined ? "" : actualProps[property_id]

            infoLines.push(
                <TextField
                    key={property_id}
                    id={property_id}
                    label={name}
                    value={actualValue}
                    disabled={readOnly}
                    variant={"outlined"}
                    onChange={(event) => {
                        onHandleChange(property_id, event.target.value)
                    }}
                />
            )
        }
    })

    return (
        <Stack direction={"column"} spacing={theme.spacing(2)}>
            {infoLines}
        </Stack>

    )
}

export function DisplayQrCode(props) {
    const serial = props.serial

    const url = getHostingUrl() + '/#/device_info/' + serial

    const generatePdf = (qrCodeURL) => {
        const doc = new jsPDF("portrait", "mm", "a4");
        doc.addImage(qrCodeURL, "JPEG", 35, 45, qr_code_size_mm, qr_code_size_mm);
        doc.text(serial, 35, 35);
        doc.save("qr__" + serial + ".pdf");
    };

    function printQRCode(){
        const qrCodeURL1 = document.getElementById("generated_qr_code");
        const qrCodeURL = qrCodeURL1 ?.toDataURL("image/png") .replace("image/png", "image/octet-stream");
        let aEl = document.createElement("a");
        aEl.href = qrCodeURL;
        generatePdf(qrCodeURL);
    }

    return (
        <Stack direction={"column"} justifyContent={"center"}>
            <QRCode
                id={"generated_qr_code"}
                size={256}
                value={url}/>
            <Button onClick={()=>printQRCode()}><PrintIcon /> Print QR Code</Button>
        </Stack>
    )
}
