import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Button} from "@mui/material";
import {Link as RouterLink} from 'react-router-dom';
import {useSelector} from "react-redux";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {theme} from "../../style/theme";
import {dispatchToStore} from "../../store/configureStore";
import {userLoginLogout} from "../../store/slices/userLogin";
import {configurationReset} from "../../store/slices/configuration";
import Stack from "@mui/material/Stack";

export default function UserMenu() {
    const userName = useSelector(state => state.userLogin.userName)

    const loggedInUserAccess = useSelector(state => state.userLogin.access)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuItems = []
    menuItems.push(<MenuItem key="account" onClick={handleClose} component={RouterLink} to={"/user_account"}>My
        Account</MenuItem>)

    if (loggedInUserAccess.userManagement) {
        menuItems.push(<MenuItem key="userManagement" onClick={handleClose} component={RouterLink}
                                 to={"/user_management"}>User
            Management</MenuItem>)

    }

    menuItems.push(<MenuItem key="logout" onClick={() => {
        dispatchToStore(userLoginLogout())
        dispatchToStore(configurationReset({}))
    }}>Logout</MenuItem>)


    return (
        <div>
            <Button
                size="medium"
                edge="start"
                aria-label="menu"
                sx={{mr: 2, color: theme.palette.primary,}}
                onClick={handleClick}
            >
                <Stack direction={"row"} gap={1} >
                <AccountCircleOutlinedIcon/>
                {userName}
                </Stack>
            </Button>


            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {menuItems}
            </Menu>
        </div>
    );
}

function login() {
    return (
        <Button sx={{color: theme.palette.primary.contrastText,}} component={RouterLink} to={'/login'}>Login</Button>
    )
}

function logout() {
    return (
        <UserMenu/>
    )
}

export function UserLogin() {
    const userLogin = useSelector(state => state.userLogin)

    return (
        <>
            {userLogin.loggedIn ? logout() : login()}
        </>

    )
}
