import React, {useEffect} from 'react';
import CustomCard from "../../../components/generic/CustomCard";
import {theme} from "../../../style/theme";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {dispatchToStore} from "../../../store/configureStore";
import {useParams} from "react-router";
import {Button, Stack, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {Link as RouterLink} from "react-router-dom";
import {stockPropertiesLoad} from "../../../store/slices/stock/properties";

export default function PropertiesDetailsPage() {

    const {selectedPropertyId} = useParams()

    const propertiesList = useSelector(store => store.stockProperties.items)

    const property = propertiesList[selectedPropertyId]

    useEffect(() => {
        // loading page effect
        dispatchToStore(stockPropertiesLoad({}))

        // unloading page effect
        return () => {

        }

    }, []);

    return (
        <Grid container spacing={theme.spacing(2)} sx={{padding: theme.spacing(2)}}>
            <Grid item xs={12}>
                <CustomCard caption={"View Product Details"}>
                    <Stack direction={"column"} spacing={2}>

                        <TextField id={"desc"} label={"Name"} value={property["desc"]} variant={"standard"}
                                   disabled={true} />

                        <TextField id={"type"} label={"Type"} value={property["type"]} variant={"standard"}
                                   disabled={true} />

                        <TextField id={"modified_at"} label={"Modified at"} value={property["modified_at"]}
                                   variant={"standard"} disabled={true}/>

                        <Stack direction={"row"} alignItems={"center"} gap={1} justifyContent={"center"}>


                            <Button size={"large"}
                                    color={"primary"}
                                    sx={{marginTop: 4}}
                                    component={RouterLink}
                                    variant={"outlined"}
                                    to={'/settings/properties'}

                                    onClick={() => {

                                    }}>
                                <CloseIcon sx={{marginRight: 2}}/>
                                Close
                            </Button>


                        </Stack>
                    </Stack>
                </CustomCard>
            </Grid>

        </Grid>

    )


}

