import React, {useState} from 'react';
import {Button, IconButton, Modal, Table, TableBody, TableCell, TableHead, TableRow, TextField} from "@mui/material";
import {theme} from "../../style/theme";
import Grid from "@mui/material/Grid";
import CustomCard from "../../components/generic/CustomCard";
import {useSelector} from "react-redux";
import {defaultAccess, userLoginAccessProps} from "../../store/slices/userLogin";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Stack from "@mui/material/Stack";
import {dispatchToStore} from "../../store/configureStore";

import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {snackbarEnqueue} from "../../store/slices/snackbar";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function NewUserModal(props) {
    const displayModal = props.editNewUser
    const onCancel = props.onCancel
    const onCreate = props.onCreate

    const [formValues, setFormValues] = useState({
        userId: "",
        password: "",
        password_repeat: "",
        passwordsNotMatching: false,
        passwordTooShort: false,
    });

    function resetFormFields() {
        setFormValues({
            newUserName: "",
            newPassword: "",
            newPasswordRepeat: "",
            passwordsNotMatching: false,
            passwordTooShort: false,
        })
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    function getErrorText(){
        if (formValues.passwordTooShort){
            return "Password too short"
        }
        if (formValues.passwordsNotMatching){
            return "passwords do not match"
        }
        return ""
    }

    return (
        <Modal
            open={displayModal}>
            <Box sx={modalStyle}>
                <Stack spacing={2} direction="column">

                    <Typography variant={"h4"}>
                        Create New User
                    </Typography>

                    <TextField
                        id="newUserName"
                        autoComplete='off'
                        variant="outlined"
                        type="text"
                        className="form-control"
                        label="User Name"
                        name="newUserName"
                        defaultValue={""}
                        onChange={handleInputChange}
                    />

                    <TextField
                        id="newPassword"
                        autoComplete='off'
                        variant="outlined"
                        type="password"
                        className="form-control"
                        label="Password"
                        name="newPassword"
                        onChange={handleInputChange}
                    />

                    <TextField
                        id="newPasswordRepeat"
                        autoComplete='off'
                        variant="outlined"
                        type="password"
                        className="form-control"
                        label="Repeat Password"
                        name="newPasswordRepeat"
                        error={formValues.passwordsNotMatching || formValues.passwordTooShort}
                        helperText={getErrorText()}
                        onChange={handleInputChange}
                    />

                    <Stack direction={"row"} justifyContent={"center"} spacing={2}>
                        <Button variant="contained" color="primary" onClick={() => {
                            if (formValues.newPassword.length < 4) {
                                setFormValues({...formValues, passwordTooShort: true,});
                                return
                            }
                            if (formValues.newPassword === formValues.newPasswordRepeat) {
                                onCreate(formValues.newUserName, formValues.newPassword)
                                resetFormFields()
                            } else {
                                setFormValues({...formValues, passwordsNotMatching: true,});
                            }
                        }}>Create</Button>
                        <Button variant="contained" color="secondary" onClick={() => {
                            onCancel()
                            resetFormFields()
                        }}>Cancel</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>

    )
}

function RenderRow(props) {
    const userId = props.userId
    const initialAccess = props.access

    const onDelete = props.onDelete
    const onUpdate = props.onUpdate

    const [access, setAccess] = useState({...initialAccess})
    const [edit, setEdit] = useState(false)

    function toggleAccess(name) {
        if (edit) {
            const value = !access[name]
            setAccess((access) => {
                return {...access, [name]: value}
            })
        }
    }

    function toggleEdit(_userId) {
        if (edit) {
            // apply
            onUpdate(_userId, access)
        }
        setEdit(!edit)
    }

    return (
        <TableRow>
            <TableCell>{userId}</TableCell>

            {Object.entries(userLoginAccessProps).map(([k, i]) => {
                return (
                    <TableCell key={k}>{
                        access[i] ?
                            <IconButton disabled={!edit} color={edit ? "primary" : "secondary"} onClick={() => {
                                toggleAccess(i)
                            }}><DoneOutlineOutlinedIcon/></IconButton>
                            :
                            <IconButton disabled={!edit} color={edit ? "primary" : "secondary"} onClick={() => {
                                toggleAccess(i)
                            }}><RemoveCircleOutlineRoundedIcon/></IconButton>
                    }
                    </TableCell>
                )
            })}

            <TableCell>
                <Stack direction={"row"}>
                    <IconButton color={"primary"} onClick={() => {
                        onDelete(userId)
                    }} disabled={userId === "root"}>
                        <DeleteForeverRoundedIcon/>
                    </IconButton>

                    <IconButton color={"primary"} onClick={() => {
                        toggleEdit(userId)
                    }} disabled={userId === "root"}>
                        {edit ? <SaveAltRoundedIcon/> : <EditRoundedIcon/>}
                    </IconButton>

                </Stack>
            </TableCell>
        </TableRow>
    )
}

export default function UserManagementPage(props) {

    const users = useSelector(state => state.userLogin.userList)
    const loggedInUser = useSelector(state => state.userLogin.access)

    const [editNewUser, setEditNewUser] = useState(false)

    function createUser(userId, password="some_random_pass", userAccess = defaultAccess()) {
        console.info("create user", userId, userAccess)
        setEditNewUser(false)


    }

    function deleteUser(userId) {

        console.warn("delete user", userId)

        dispatchToStore(snackbarEnqueue({
            message: "deleted user " + userId,
            severity: "warning"
        }))
    }

    function updateUser(userId, access) {
        console.info("update user", userId, access)


    }

    function cancelEditUser() {
        setEditNewUser(false)
    }

    const tableBody = []
    Object.entries(users).forEach(([userId, access]) => {
        tableBody.push(<RenderRow key={userId} userId={userId} access={access}
                                  onDelete={deleteUser}
                                  onUpdate={updateUser}/>
        )
    })


    return (
        <Grid container direction={"row"} spacing={theme.spacing(2)} sx={{padding: theme.spacing(2)}}>
            <Grid item xs={12}>
                <CustomCard caption={"User Management"}>
                    {loggedInUser["userManagement"] ?
                        <Box>
                            <Table>

                                <TableHead>
                                    <TableRow sx={{background: "lightgray"}}>
                                        <TableCell>User</TableCell>
                                        {Object.entries(userLoginAccessProps).map(([k, i]) => {
                                            return (
                                                <TableCell key={k}>{i}</TableCell>
                                            )
                                        })}
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>


                                <TableBody>
                                    {tableBody}
                                </TableBody>

                            </Table>

                            <Stack direction={"row"} alignItems={"center"} gap={1} justifyContent={"center"}>
                                <NewUserModal
                                    editNewUser={editNewUser}
                                    onCreate={createUser}
                                    onCancel={cancelEditUser}
                                />

                                <Button size={"large"} color={"primary"} sx={{marginTop: 4}}
                                        onClick={() => {
                                            setEditNewUser(true)
                                        }}>
                                    <AddCircleOutlineIcon sx={{marginRight: 2}}/>
                                    Add User
                                </Button>


                            </Stack>
                        </Box>
                        :

                        <Typography>not allowed</Typography>
                    }

                </CustomCard>
            </Grid>

        </Grid>
    )
}