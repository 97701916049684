import React, {useEffect} from 'react';
import CustomCard from "../../../components/generic/CustomCard";
import {theme} from "../../../style/theme";
import Grid from "@mui/material/Grid";
import DatabaseTable from "../../../components/generic/DatabaseTable";
import {dispatchToStore} from "../../../store/configureStore";
import {useSelector} from "react-redux";
import {stockLocationsDelete, stockLocationsLoad, stockLocationsUpsert} from "../../../store/slices/stock/locations";


export default function LocationsSettingsPage(props) {

    const locations = useSelector(store => store.stockLocations)

    useEffect(() => {
        // loading page effect
        dispatchToStore(stockLocationsLoad({}))

        // unloading page effect
        return () => {

        }

    }, []);

    // ---
    function onUpsertLocationRow(uniqueKey, row){
        dispatchToStore(stockLocationsUpsert({
            location_id: row["location_id"],
            name: row["name"],
            desc: row["desc"],
        }))
    }

    function onDeleteLocationRow(uniqueKey, row){
        dispatchToStore(stockLocationsDelete({
            location_id: row["location_id"],
            name: row["name"],
            desc: row["desc"],
        }))
    }

    return (
        <Grid container spacing={theme.spacing(2)} sx={{padding: theme.spacing(2)}}>

            <Grid item xs={12}>
                <CustomCard caption={"Locations"}>
                    <DatabaseTable
                        uniqueKey={locations.uniqueKey}
                        header={locations.header}
                        editAble={locations.editAble}
                        rows={locations.items}

                        onUpsertRow={onUpsertLocationRow}
                        onDeleteRow={onDeleteLocationRow}
                    />
                </CustomCard>
            </Grid>

        </Grid>
    )


}

