import React, {useState} from "react";
import {Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField} from "@mui/material";
import Stack from "@mui/material/Stack";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import SaveAltRoundedIcon from "@mui/icons-material/SaveAltRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Box from "@mui/material/Box";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {random} from "./utils";


function RenderRow(props) {
    const uniqueKey = props.uniqueKey
    const header = props.header
    const editAble = props.editAble
    const initialRow = props.row

    const onDelete = props.onDelete
    const onUpdate = props.onUpdate

    const [row, setRow] = useState({...initialRow})
    const [edit, setEdit] = useState(false)

    function toggleEdit(_uniqueKey) {
        if (edit) {
            // apply
            onUpdate(_uniqueKey, row)
        }
        setEdit(!edit)
    }

    function onChange(k, e) {
        setRow({...row, [k]: e})
    }

    return (
        <TableRow>

            {Object.keys(header).map((k) => {
                return (
                    <TableCell key={k}>
                        {edit === false ?
                            row[k]
                            :
                            editAble[k] ?
                                <TextField id={k} label={header[k]} value={row[k]}
                                           onChange={(e) => onChange(k, e.target.value)}/>
                                :
                                row[k]}
                    </TableCell>
                )
            })}

            <TableCell>
                <Stack direction={"row"} justifyContent={"right"}>
                    <IconButton color={"primary"} onClick={() => {
                        toggleEdit(row[uniqueKey])
                    }}>
                        {edit ? <SaveAltRoundedIcon/> : <EditRoundedIcon/>}
                    </IconButton>

                    <IconButton color={"primary"} onClick={() => {
                        onDelete(row[uniqueKey], row)
                    }}>
                        <DeleteForeverRoundedIcon/>
                    </IconButton>

                </Stack>
            </TableCell>
        </TableRow>
    )
}


export default function DatabaseTable(props) {
    const uniqueKey = props.uniqueKey
    const tableHeader = props.header
    const editAble = props.editAble
    const tableRows = props.rows
    const onUpsertRow = props.onUpsertRow
    const onDeleteRow = props.onDeleteRow

    function deleteRow(uniqueKey, row) {
        onDeleteRow(uniqueKey, row)
    }

    function updateRow(uniqueKey, row) {
        onUpsertRow(uniqueKey, row)
    }

    function newRow() {
        const row = {}
        Object.keys(tableHeader).forEach((k) => {
            row[k] = "new" + random(999999)
        })

        row[uniqueKey] = -1

        updateRow(-1, row);

    }

    const tableBody = []
    Object.entries(tableRows).forEach(([rowId, row]) => {
            if (row["archive"] === 0) {

                tableBody.push(<RenderRow key={rowId}
                                          uniqueKey={uniqueKey}
                                          header={tableHeader}
                                          editAble={editAble}
                                          row={row}
                                          onDelete={deleteRow}
                                          onUpdate={updateRow}/>
                )
            }
        }
    )


    return (

        <Box>
            <Table size="small">

                <TableHead>
                    <TableRow sx={{background: "lightgray"}}>
                        {Object.entries(tableHeader).map(([k, i]) => {
                            return (
                                <TableCell key={k}>{i}</TableCell>
                            )
                        })}
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>


                <TableBody>
                    {tableBody}
                </TableBody>

            </Table>

            <Stack direction={"row"} alignItems={"center"} gap={1} justifyContent={"center"}>


                <Button size={"large"} color={"primary"} sx={{marginTop: 4}}
                        onClick={() => {
                            newRow()
                        }}>
                    <AddCircleOutlineIcon sx={{marginRight: 2}}/>
                    Add New
                </Button>


            </Stack>
        </Box>
    )
}