import Box from "@mui/material/Box";
import {
    Button, Checkbox, Fab, FormControlLabel, FormGroup,
    IconButton, Popover,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import {Link as RouterLink} from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import React, {useEffect, useState} from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {theme} from "../../style/theme";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import AddIcon from '@mui/icons-material/Add';
import {makeStyles} from "@mui/styles";


const empty_filter_id = "-blank-"

const useStyles = makeStyles(theme => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

function RenderItem(props) {
    const header = props.header
    const row = props.row
    const uniqueKey = props.uniqueKey

    return (
        <TableRow>

            {Object.entries(header).map(([id, h]) => {
                return (
                    <TableCell key={id}>
                        {row[id] !== undefined ? row[id] : "-"}
                    </TableCell>
                )
            })}

            <TableCell>
                <Stack direction={"row"} justifyContent={"right"}>
                    <IconButton
                        component={RouterLink}
                        to={'details/'+row[uniqueKey]}
                        color={"primary"}
                        size={"small"}
                    >
                        <VisibilityIcon/>
                    </IconButton>

                    <IconButton
                        color={"primary"}
                        component={RouterLink}
                        to={'edit/'+row[uniqueKey]}
                        size={"small"}
                    >
                        <EditRoundedIcon/>
                    </IconButton>

                    <IconButton
                        color={"primary"}
                        component={RouterLink}
                        to={'delete/'+row[uniqueKey]}
                        size={"small"}
                    >
                        <DeleteForeverRoundedIcon/>
                    </IconButton>

                </Stack>
            </TableCell>

        </TableRow>
    )
}

function TableHeadFilter(props){
    const columnId = props.id
    const caption = props.caption
    const filterOptions = props.filterOptions[columnId] !== undefined?props.filterOptions[columnId]:{}
    const activeFilters = props.activeFilters
    const handleUpdate = props.handleUpdate
    const handleDeactivateFilter = props.handleDeactivateFilter

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false)

    function handleOpen(event){
        setAnchorEl(event.currentTarget);
        setOpen(true)
    }

    function handleClose() {
        setAnchorEl(null);
        setOpen(false)
    }

    function handleChecked(optionId, value){
        handleUpdate(columnId, optionId, value)
    }

    return(
        <TableCell>
            <Stack direction={"row"}>
                <Box>{caption}</Box>
                <ExpandMoreIcon aria-describedby={caption} onClick={handleOpen} />
                {activeFilters[columnId] ?
                    <FilterAltOffIcon onClick={()=>handleDeactivateFilter(columnId)}/>
                    :
                    <></>
                }
                <Popover
                    id={caption}
                    anchorEl={anchorEl}

                    open={open}
                    onClose={handleClose}

                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Stack direction="column" sx={{padding: theme.spacing(1)}}>

                        <FormGroup>
                            {Object.entries(filterOptions).map(([filterId,enabled])=>{
                                return(
                                    <FormControlLabel key={filterId}
                                        control={
                                            <Checkbox
                                                size={"small"}
                                                checked={enabled}
                                                onChange={()=>handleChecked(filterId,!enabled)}/>
                                        }
                                        label={filterId}
                                    />
                                )
                            })}
                        </FormGroup>
                    </Stack>
                </Popover>

            </Stack>
        </TableCell>
    )
}

function calcOptions(columnId, rows){
    const uniqueOptions = {}

    Object.entries(rows).forEach(([idx, row])=>{
        if (!row["archive"]) {
            const value = row[columnId]
            if (value !== undefined) {
                uniqueOptions[value] = true
            } else {
                uniqueOptions[empty_filter_id] = true
            }
        }
    })
    return uniqueOptions
}

export default function DatabasePreviewList(props) {
    const uniqueKey = props.uniqueKey
    const tableHeader = props.header
    const tableRows = props.rows

    const [filterOptions, setFilterOptions] = useState({})
    const [activeFilters, setActiveFilters] = useState({})

    function filterRows(rows){
        const filteredRows = []
        Object.entries(rows).forEach(([rowId, row])=>{
            let allow = true
            Object.entries(filterOptions).forEach(([columnId, filterValues])=>{
                if (activeFilters[columnId] !== undefined) {
                    const value = row[columnId] !== undefined ? row[columnId] : empty_filter_id
                    if (filterValues[value]){
                        // enabled

                    } else {
                        // disabled
                        allow = false
                    }
                }
            })

            if (allow){
                filteredRows.push(row)
            }
        })

        return filteredRows
    }

    const tableBody = []
    const filteredRows = filterRows(tableRows)


    // sort desc
    filteredRows.sort((a, b) => {
        return b[uniqueKey] - a[uniqueKey];
    });


    Object.entries(filteredRows).forEach(([rowId, row]) => {
        if (!row["archive"]) {
            tableBody.push(<RenderItem key={rowId}
                                       uniqueKey={uniqueKey}
                                       header={tableHeader}
                                       row={row}
                />
            )
        }
    })

    useEffect(() => {
        // loading page effect

        // unloading page effect
        return () => {

        }

    }, []);

    useEffect(() => {
        // when tableHeader changes, update filter
        const options = {}
        Object.entries(tableHeader).forEach(([columnId, h]) => {
            options[columnId] = calcOptions(columnId, tableRows)
        })
        setFilterOptions(options)

        setActiveFilters({})
        return () => {

        }

    }, [tableHeader]);

    function updateFilter(columnId, filterId, enabled){
        const newFilterOptions = {...filterOptions}
        newFilterOptions[columnId][filterId] = enabled
        setFilterOptions(newFilterOptions)

        const newActiveFilter = {...activeFilters}
        newActiveFilter[columnId] = true
        setActiveFilters(newActiveFilter)
    }

    function deactivateFilter(columnId){
        const newFilterOptions = {...filterOptions}
        Object.entries(newFilterOptions[columnId]).forEach(([filterId, value])=>{
            newFilterOptions[columnId][filterId] = true;
        })
        setFilterOptions(newFilterOptions)

        const newActiveFilter = {...activeFilters}
        delete newActiveFilter[columnId]
        setActiveFilters(newActiveFilter)
    }

    const classes = useStyles()

    return (

        <Box>
            <Table size="small">

                <TableHead>
                    <TableRow sx={{background: "lightgray"}}>
                        {Object.entries(tableHeader).map(([columnId, h]) => {
                            const columnName = h.desc


                            return (
                                <TableHeadFilter key={columnId}
                                                 id={columnId}
                                                 caption={columnName}
                                                 filterOptions={filterOptions}
                                                 activeFilters={activeFilters}
                                                 handleUpdate={updateFilter}
                                                 handleDeactivateFilter={deactivateFilter}
                                />
                            )
                        })}
                        <TableCell align="right">
                            <Fab color="primary"
                                 size="small"
                                 aria-label="add"
                                 component={RouterLink}
                                 to={'edit/-1'}
                            >
                                <AddIcon />
                            </Fab>
                        </TableCell>
                    </TableRow>
                </TableHead>


                <TableBody>
                    {tableBody}
                </TableBody>

            </Table>

        </Box>
    )
}
