import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import {theme} from "../../style/theme";
import Toolbar from "@mui/material/Toolbar";

const styles = {
    boldText: {
        fontWeight: "bold"
    },
    card: {
        borderRadius: 0,
        marginBottom: theme.spacing(2)
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        height: "64px",
    },
    cardContent: {},
    cardContentCenter: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
}

export default function CustomCard(props) {

    const caption = props.caption !== undefined ? props.caption : "<unnamed>"
    const toolbar = props.toolbar !== undefined ? props.toolbar : <></>
    const color = props.color !== undefined ? props.color : undefined

    const content = props.children
    const contentStyle = {}
    const headerStyle = {
        ...styles.cardHeader,
    }

    if (color !== undefined){
        headerStyle.backgroundColor = color
    }

    if (props.height !== undefined) {
        if (props.height === "auto") {
            contentStyle.height = "100%"
        } else {
            contentStyle.height = props.height
        }
    }

    if (props.maxWidth !== undefined) {
        contentStyle.maxWidth = props.maxWidth
    }

    return (
        <Card sx={styles.card} style={{overflow: "auto"}}>
            <CardHeader
                sx={headerStyle}
                title={
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={2}>
                        <Typography gutterBottom component="p" sx={styles.boldText}>
                            {caption}
                        </Typography>
                        <Toolbar variant="dense" disableGutters={true}>
                            {toolbar}
                        </Toolbar>
                    </Stack>
                }
            />

            <CardContent sx={props.center !== undefined ? styles.cardContentCenter : styles.cardContent} style={contentStyle}>

                {content}

            </CardContent>

        </Card>
    )
}