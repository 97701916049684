import React, {useEffect, useState} from 'react';
import CustomCard from "../../components/generic/CustomCard";
import {theme} from "../../style/theme";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {dispatchToStore} from "../../store/configureStore";
import {stockProductsLoad} from "../../store/slices/stock/products";
import {stockLocationsLoad} from "../../store/slices/stock/locations";
import {useParams} from "react-router";
import {Button, FormControl, InputLabel, Select, Stack, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {Link as RouterLink} from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import MenuItem from "@mui/material/MenuItem";
import {EditDeviceProps} from "../../components/stock/item";
import {stockDevicesLoad, stockDevicesUpsert} from "../../store/slices/stock/devices";

function ProductSelector(props) {

    const currentSelect = props.selected
    const products = props.products
    const onHandleChange = props.onHandleChange
    const isNewDevice = props.isNewDevice

    const options = []

    Object.entries(products).forEach(([id, value]) => {
            if (!isNewDevice || Number(value["archive"]) === 0) {
                options.push(<MenuItem value={id} key={id}>{value["name"]}</MenuItem>)
            }
        }
    )

    return (
        <FormControl fullWidth>
            <InputLabel id="product_select">Product</InputLabel>
            <Select
                value={currentSelect}
                label={"product"}
                onChange={(event) => {
                    onHandleChange(event.target.value)
                }}
                disabled={!isNewDevice}

            >

                <MenuItem value={"-1"} key={"-1"}>None</MenuItem>

                {options}

            </Select>
        </FormControl>
    )
}


function LocationSelector(props) {

    const currentSelect = props.selected
    const locations = props.locations
    const onHandleChange = props.onHandleChange

    const options = []

    Object.entries(locations).forEach(([id, value]) => {
            if (Number(value["archive"]) === 0) {
                options.push(<MenuItem value={id} key={id}>{value["name"]}</MenuItem>)
            }
        }
    )

    return (
        <FormControl fullWidth>
            <InputLabel id="location_select">Location</InputLabel>
            <Select
                value={currentSelect}
                label={"location"}
                onChange={(event) => {
                    onHandleChange(event.target.value)
                }}
            >

                <MenuItem value={"-1"} key={"-1"}>None</MenuItem>

                {options}

            </Select>
        </FormControl>
    )
}


export default function StockDeviceEditPage() {

    const {selectedDeviceId} = useParams()

    const devices = useSelector(store => store.stockDevices.items)
    const locations = useSelector(store => store.stockLocations.items)
    const products = useSelector(store => store.stockProducts.items)

    const [device, setDevice] = useState({})
    const [expectedProperties, setExpectedProperties] = useState({})
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        // loading page effect
        dispatchToStore(stockDevicesLoad({}))
        dispatchToStore(stockProductsLoad({}))
        dispatchToStore(stockLocationsLoad({}))

        // start with empty device
        setDevice({
            device_id: -1,
            serial: -1,
            product_id: -1,
            location_id: -1,
            props: {}
        })

        // unloading page effect
        return () => {

        }

    }, []);

    useEffect(() => {
        // loading page effect
        if (loaded){
            // already loaded
            return
        }

        if (Object.keys(device).length <= 0){
            // we need to wait for the device to initialize
            return
        }

        const newLoaded = {}
        if (devices !== undefined){
            newLoaded.devices = true
        }
        if (products !== undefined){
            newLoaded.products = true
        }
        if (locations !== undefined){
            newLoaded.locations = true
        }
        if ((Number(selectedDeviceId) === -1) || (devices[selectedDeviceId].props !== undefined)){
            newLoaded.props = true
        }

        if (newLoaded.products === true
            && newLoaded.devices === true
            && newLoaded.locations === true
            && newLoaded.props === true
        ) {

            onLoadingReady()

            setLoaded(true)
        }

    }, [device, devices, products, locations]);


    useEffect(() => {
            if (device.product_id === undefined || device.product_id === -1){
                return
            }

            console.log("product id changed", device, device.product_id)

            setExpectedProperties(products[device.product_id].expected_props)
            //addExpectedProps()


    }, [device.product_id]);

    function onLoadingReady(){

        if (Number(selectedDeviceId) !== -1){
            const d = devices[selectedDeviceId]

            const k = {...device,
                device_id: d.device_id,
                serial: d.serial,

                product_id: d.product_id,
                location_id: d.location_id,
                modified_at: d.modified_at,
                created_at: d.created_at,

            }

            Object.entries(d.props).forEach(([name, prop])=>{
                if (k.props[name] === undefined){
                    k.props[name] = prop
                }
            })

            setDevice(k)
        }


    }

    // ---
    function onUpsertItemRow(row) {
        const props = {}

        Object.entries(row.props).forEach(([name, value]) => {
            props[name] = value
        })

        dispatchToStore(stockDevicesUpsert({
            device_id: row["device_id"],
            serial: row["serial"],
            product_id: row["product_id"],
            location_id: row["location_id"],
            props: props,
        }))
    }

    function onChangeProduct(newProduct) {
        setDevice({...device, "product_id": newProduct})
    }

    function onChangeLocation(newLocation) {
        setDevice({...device, "location_id": newLocation})
    }

    function onChangeProp(name, value) {
        console.log("onChangeProp", name, value)
        setDevice({...device, "props": {...device.props, [name]: value}})
    }

    return (
        <Grid container spacing={theme.spacing(4)} sx={{padding: theme.spacing(2)}}>
            <Grid item xs={12}>
                <CustomCard caption={"Edit Device Details [" + device["serial"] + "]"}>
                    {!loaded ?
                        "loading ..."
                        :
                        <Stack direction="column" spacing={2}>

                            <ProductSelector products={products} onHandleChange={onChangeProduct}
                                             selected={device["product_id"]}
                                             isNewDevice={device["device_id"] === -1}/>

                            <LocationSelector locations={locations} onHandleChange={onChangeLocation}
                                              selected={device["location_id"]}/>

                            <TextField id={"modified_at"} label={"Modified at"} value={device["modified_at"]}
                                       disabled={true}/>

                            <TextField id={"created_at"} label={"Created at"} value={device["created_at"]}
                                       disabled={true}/>

                            <EditDeviceProps
                                expectedProperties={expectedProperties}
                                actualProps={device["props"]}
                                onHandleChange={onChangeProp}
                            />

                            <Stack direction={"row"} alignItems={"center"} gap={1} justifyContent={"center"}>

                                <Button size={"large"}
                                        color={"primary"}
                                        sx={{marginTop: 4}}
                                        component={RouterLink}
                                        to={'/stock_devices'}
                                        variant={"outlined"}

                                        onClick={() => {
                                            onUpsertItemRow(device)
                                        }}>
                                    <SaveIcon sx={{marginRight: 2}}/>
                                    Save
                                </Button>

                                <Button size={"large"}
                                        color={"primary"}
                                        sx={{marginTop: 4}}
                                        component={RouterLink}
                                        to={'/stock_devices'}
                                >
                                    <CloseIcon sx={{marginRight: 2}}/>
                                    Cancel
                                </Button>


                            </Stack>
                        </Stack>
                    }
                </CustomCard>
            </Grid>

        </Grid>

    )


}

