import {put} from "redux-saga/effects";
import {apiRequest} from "../apiConnector";
import {stockLocationsLoad, stockLocationsLoaded} from "../../slices/stock/locations";
import {snackbarEnqueue} from "../../slices/snackbar";
import {configurationSetDoneStatus} from "../../slices/configuration";
import {waitFor} from "../../../components/generic/utils";


export function* loadStockLocations(action) {
    const [responseList, success] = yield apiRequest("stock-location", "list", {})
    if (!success) {
        return
    }

    yield put(stockLocationsLoaded({items: responseList.data.list}))
}

export function* upsertStockLocation(action) {

    const data = action.payload

    const [responseList, success] = yield apiRequest("stock-location", "upsert", data)

    if (!success) {
        // error
        yield put(snackbarEnqueue({
            message: "update failed on location " + action.payload.name + " " + responseList.info,
            severity: "error"
        }))

    } else {
        // ok

        yield put(snackbarEnqueue({
            message: "update success on location " + action.payload.name,
            severity: "success"
        }))
    }

    yield put(stockLocationsLoad())
}

export function* deleteStockLocation(action){

    const data = action.payload

    const [responseList, success] = yield apiRequest("stock-location", "delete", data)

    if (!success) {
        // error
        yield put(snackbarEnqueue({
            message: "delete failed on location " + action.payload.name + " " + responseList.info,
            severity: "error"
        }))

    } else {
        // ok
        yield put(snackbarEnqueue({
            message: "delete success on location " + action.payload.name,
            severity: "success"
        }))
    }

    yield put(stockLocationsLoad())
}

export function* initializeLocations(action) {

    yield put(configurationSetDoneStatus({name: "stockLocations", done: false}))

    // start
    yield put(stockLocationsLoad({}))

    // wait for products to be loaded
    yield waitFor(stockLocationsLoaded, state => state.stockLocations.loaded)

    yield put(configurationSetDoneStatus({name: "stockLocations", done: true}))

}
