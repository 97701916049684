import Chip from "@mui/material/Chip";
import {Button, Select, Stack} from "@mui/material";
import React, {useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";

function DisplayTags(props) {
    const expectedProps = props.expectedProps
    const properties = props.properties
    const onDelete = props.onDelete !== undefined ? props.onDelete : null

    const tagChips = []

    Object.entries(expectedProps).forEach(([property_id, property]) => {
        if (!property["archive"]) {
            const handleOnDelete = (e) => {
                onDelete(property_id)
            }
            const type = properties[property_id]["type"]
            const name = properties[property_id]["desc"]
            tagChips.push(<Grid item key={property_id}>
                        <Chip label={name + " :: " + type} variant={"outlined"}
                                onDelete={onDelete !== null ? handleOnDelete : null}/>
                    </Grid>)
        }
    })

    return (
        <Grid container spacing={2}>
            {tagChips}
        </Grid>
    )
}

export function DisplayExpectedProps(props) {
    const expectedProps = props.expectedProps
    const editable = props.editable !== undefined ? props.editable : false
    const onDelete = props.onDeleteKey !== undefined ? props.onDeleteKey : null
    const onAdd = props.onAddKey !== undefined ? props.onAddKey : null

    const properties = useSelector(store => store.stockProperties)

    const [addNewActive, setAddNewActive] = useState(false)
    const [name, setName] = useState("")

    const [selectedProp, setSelectedProp] = useState(Object.keys(properties.items)[0])

    function toggleAddNew() {
        setAddNewActive(!addNewActive)
        // reset name
        setName("")
    }

    return (
        <Stack direction={"column"} spacing={2}>
            {editable ?
                <Stack direction={"column"} spacing={2}>
                    <Stack direction={"column"} spacing={2}>
                        <DisplayTags expectedProps={expectedProps}
                                     properties={properties.items}
                                     onDelete={onDelete}

                        />

                        {addNewActive ?
                            <Stack direction={"row"} spacing={2} justifyContent={"center"}>

                                <Select
                                    id={"prop_selector"}
                                    value={selectedProp}
                                    label={"Property"}
                                    onChange={(event) => {
                                        setSelectedProp(event.target.value)
                                    }}

                                >

                                    {Object.entries(properties.items).map(([property_id, value]) => {
                                            const name = value["desc"]
                                            return (<MenuItem value={property_id} key={property_id}>{name}</MenuItem>)
                                        }
                                    )}

                                </Select>

                                <Button variant={"outlined"}
                                        onClick={() => {
                                            onAdd(selectedProp)
                                            toggleAddNew()
                                        }}
                                >Apply</Button>

                                <Button onClick={() => toggleAddNew()}>Cancel</Button>

                            </Stack>
                            :
                            <Button variant={"outlined"} onClick={() => toggleAddNew()}>Add Key</Button>

                        }
                    </Stack>

                </Stack>

                :

                <DisplayTags expectedProps={expectedProps} properties={properties.items}/>
            }

        </Stack>
    )
}