import {createSlice} from '@reduxjs/toolkit'

export const userLoginAccessProps = [
    "debug",
    "userManagement",
]

export function defaultAccess(){
    const l = {}
    Object.entries(userLoginAccessProps).forEach( ([k, i]) => {
        l[i] = false
    })
    return l
}

export const loginSlice = createSlice({
    name: 'userLogin',
    initialState: {
        loggedIn: undefined,
        loggingIn: false,

        loginCheckOk: undefined,

        accessLoaded: false,

        userName: "",

        access: {},
        userList: {},

    },
    reducers: {
        userLoginRestartFromSession: (state, {payload}) => {
            state.loggingIn = true
            state.loggedIn = undefined
            state.loginCheckOk = undefined
            state.userList = {}
            state.accessLoaded = false
            state.userName = ""
            state.access = defaultAccess()

        },

        userLoginStart: (state, {payload}) => {
            state.loggingIn = true
            state.loggedIn = undefined
            state.loginCheckOk = undefined
            state.userList = {}
            state.accessLoaded = false
            state.userName = ""
            state.access = defaultAccess()

        },

        userLoginAccessUpdate: (state, {payload}) => {
            const userId = payload.user_id
            const access = payload.access
            if (state.userList[userId] === undefined){
                state.userList[userId] = defaultAccess()
            }

            if (access === null){
                // delete user
                delete state.userList[userId]
                return
            }

            try {
                // try to copy fields

                state.accessLoaded = true
            } catch (e) {
                console.warn("cannot connect data")
            }
        },

        userLoginCheckResult: (state, {payload}) => {
            state.loggingIn = false
            state.loginCheckOk = payload.loginCheckOk
        },

        userLoginOk: (state, {payload}) => {
            state.loggingIn = false
            state.loggedIn = true

            state.userName = payload.user_name
        },

        userLoginFailed: (state) => {
            state.loggingIn = false
            state.loggedIn = false
        },

        userLoginLogout: (state) => {
            state.loggingIn = false
            state.loggedIn = false
            state.loginCheckOk = undefined
        },

    }
})

export const {
    userLoginRestartFromSession,
    userLoginStart, userLoginLogout,
    userLoginCheckResult,
    userLoginAccessUpdate,

    userLoginOk, userLoginFailed,

} = loginSlice.actions

export default loginSlice.reducer
