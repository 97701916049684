import {createSlice} from '@reduxjs/toolkit'

export const userSettingsSlice = createSlice({
    name: 'userSettings',
    initialState: {
        loaded: false,

        storeTimer: undefined
    },

    reducers: {
        userSettingsInitialize: (state) => {
            console.log("userSettings initializing")
            state.loaded = false
        },

        userSettingsLoaded: (state, {payload}) => {
            try {


            } catch (e) {
                console.warn("userSettings - failed to load settings - incomplete data received", payload, e)
                return
            }

            state.loading = false
            state.loaded = true
        },

    }
})

export const {
    userSettingsInitialize,
    userSettingsLoaded,

} = userSettingsSlice.actions

export default userSettingsSlice.reducer