import {put} from "redux-saga/effects";
import {apiRequest} from "../apiConnector";
import {configurationSetDoneStatus} from "../../slices/configuration";
import {stockExportLoaded} from "../../slices/stock/export";


export function* loadStockExport(action) {
    const [responseList, success] = yield apiRequest("stock-export", "list", {})
    if (!success) {
        return
    }
    console.debug(responseList.data.list)
    yield put(stockExportLoaded({items: responseList.data.list}))
}


export function* initializeExport(action) {

    yield put(configurationSetDoneStatus({name: "stockExport", done: false}))

    // ... nothing to do

    yield put(configurationSetDoneStatus({name: "stockExport", done: true}))

}
