import {put} from "redux-saga/effects";
import {apiRequest} from "../apiConnector";
import {snackbarEnqueue} from "../../slices/snackbar";
import {configurationSetDoneStatus} from "../../slices/configuration";
import {waitFor} from "../../../components/generic/utils";
import {stockPropertiesLoad, stockPropertiesLoaded, stockPropertyTypesLoaded} from "../../slices/stock/properties";


function * loadStockPropertyTypes(){

    const [responseList, success] = yield apiRequest("stock-properties", "types", {})
    if (!success) {
        return
    }

    yield put(stockPropertyTypesLoaded({propertyTypes: responseList.data.types}))
}


export function* loadStockProperties(action) {
    yield loadStockPropertyTypes()

    const [responseList, success] = yield apiRequest("stock-properties", "list", {})
    if (!success) {
        return
    }

    yield put(stockPropertiesLoaded({items: responseList.data.list}))
}

export function* upsertStockProperties(action) {

    const data = action.payload

    const [responseList, success] = yield apiRequest("stock-properties", "upsert", data)

    if (!success) {
        // error
        yield put(snackbarEnqueue({
            message: "update failed on property " + action.payload.desc + " " + responseList.info,
            severity: "error"
        }))

    } else {
        // ok

        yield put(snackbarEnqueue({
            message: "update success on property " + action.payload.desc,
            severity: "success"
        }))
    }

    yield put(stockPropertiesLoad())
}

export function* deleteStockProperties(action){

    const data = action.payload

    const [responseList, success] = yield apiRequest("stock-properties", "delete", data)

    if (!success) {
        // error
        yield put(snackbarEnqueue({
            message: "delete failed on property " + action.payload.desc + " " + responseList.info,
            severity: "error"
        }))

    } else {
        // ok
        yield put(snackbarEnqueue({
            message: "delete success on property " + action.payload.desc,
            severity: "success"
        }))
    }

    yield put(stockPropertiesLoad())
}

export function* initializeProperties(action) {

    yield put(configurationSetDoneStatus({name: "stockProperties", done: false}))

    // start
    yield put(stockPropertiesLoad({}))

    // wait for products to be loaded
    yield waitFor(stockPropertiesLoaded, state => state.stockProperties.loaded)

    yield put(configurationSetDoneStatus({name: "stockProperties", done: true}))

}
