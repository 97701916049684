import {createSlice} from "@reduxjs/toolkit";
import packageJson from "../../../package.json";

function initializeState(state){
    state.fullyLoaded = false

    Object.keys(state.done).forEach(item => {
        state.done[item] = undefined
    })
}


export const configurationSlice = createSlice({
    name: 'configuration',

    initialState: {
        fullyLoaded: false,

        done: {
            userAccess: undefined,
            userSettings: undefined,
            stockProperties: undefined,
            stockProducts: undefined,
            stockLocations: undefined,
            stockDevices: undefined,
            stockExport: undefined,
        },

        version: "warehouse-app " + packageJson.version,

    },

    reducers: {
        configurationBootstrapApp: (state) => {
            // bootstrap configuration => triggers wtx, which then triggers startup or reset
            initializeState(state)
        },

        configurationStart: (state, {payload}) => {
            console.info("**** start configuration ****")
            initializeState(state)
        },

        configurationReset: (state) => {
            console.warn("**** resetting configuration ****")
            initializeState(state)
        },

        configurationSetDoneStatus: (state, {payload}) => {
            console.info("configurationSetDoneStatus", payload.name, "=>", payload.done)
            if (payload.name in state.done){
                state.done[payload.name] = payload.done
            }
        },

        configurationReady: (state, {payload}) => {
            state.fullyLoaded = true
        }

    }
})

export const {
    configurationBootstrapApp,
    configurationStart,
    configurationReset,
    configurationSetDoneStatus,

    updateWtxServerStatus,
    updateErrorConfig,
    updateHeaterConfig,

    configurationReady,

} = configurationSlice.actions

export default configurationSlice.reducer