import {put} from "redux-saga/effects";
import {apiRequest} from "../apiConnector";
import {stockProductsLoad, stockProductsLoaded} from "../../slices/stock/products";
import {snackbarEnqueue} from "../../slices/snackbar";
import {configurationSetDoneStatus} from "../../slices/configuration";
import {waitFor} from "../../../components/generic/utils";


export function* loadStockProducts(action) {
    const [responseList, success] = yield apiRequest("stock-product", "list", {})
    if (!success) {
        return
    }

    yield put(stockProductsLoaded({items: responseList.data.list}))
}

export function* upsertStockProduct(action) {

    const data = action.payload

    const [responseList, success] = yield apiRequest("stock-product", "upsert", data)

    if (!success) {
        // error
        yield put(snackbarEnqueue({
            message: "update failed on product " + action.payload.name + " " + responseList.info,
            severity: "error"
        }))

    } else {
        // ok
        console.log(responseList)

        yield put(snackbarEnqueue({
            message: "updated success on product " + action.payload.name,
            severity: "success"
        }))
    }

    yield put(stockProductsLoad())
}

export function* deleteStockProduct(action){

    const data = action.payload

    const [responseList, success] = yield apiRequest("stock-product", "delete", data)

    if (!success) {
        // error
        yield put(snackbarEnqueue({
            message: "delete failed on product " + action.payload.name + " " + responseList.info,
            severity: "error"
        }))

    } else {
        // ok
        console.log(responseList)

        yield put(snackbarEnqueue({
            message: "delete success on product " + action.payload.name,
            severity: "success"
        }))
    }

    yield put(stockProductsLoad())
}

export function* initializeProducts(action) {

    yield put(configurationSetDoneStatus({name: "stockProducts", done: false}))

    // start
    yield put(stockProductsLoad({}))

    // wait for products to be loaded
    yield waitFor(stockProductsLoaded, state => state.stockProducts.loaded)

    yield put(configurationSetDoneStatus({name: "stockProducts", done: true}))

}
