import {createSlice} from '@reduxjs/toolkit'
import md5 from 'crypto-js/md5';

export const apiConnectorSlice = createSlice({
    name: 'apiConnector',
    initialState: {
        auth: {
            user_name: "",
            user_pass: "",
        },

        connected: false,
    },

    reducers: {
        apiConnectorInit: (state, {payload}) => {
            state.auth.user_name = ""
            state.auth.user_pass = ""
            delete state.auth.session
            state.connected = false
        },

        apiConnectorStart: (state, {payload}) => {
            if (payload.session !== undefined){
                // set by session
                state.auth.session = payload.session
            } else {
                state.auth.user_name = payload.user_name
                state.auth.user_pass = md5(payload.user_pass).toString()
            }
        },

        apiConnectorUpdateAuth: (state, {payload}) => {
            // we replace user and pass by session id
            delete state.auth.user_name
            delete state.auth.user_pass

            state.auth.session = payload.session
            state.connected = true
        }
    }
})

export const {
    apiConnectorInit,
    apiConnectorStart,
    apiConnectorUpdateAuth,

} = apiConnectorSlice.actions

export default apiConnectorSlice.reducer
