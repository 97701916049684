import React, {useEffect} from 'react';
import CustomCard from "../../../components/generic/CustomCard";
import {theme} from "../../../style/theme";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {dispatchToStore} from "../../../store/configureStore";
import {stockProductsDelete, stockProductsLoad} from "../../../store/slices/stock/products";
import {useParams} from "react-router";
import {Button, Stack, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {Link as RouterLink} from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import {DisplayExpectedProps} from "../../../components/stock/products";


export default function ProductDeletePage() {

    const {selectedProductId} = useParams()

    const productList = useSelector(store => store.stockProducts.items)

    useEffect(() => {
        // loading page effect
        dispatchToStore(stockProductsLoad({}))

        // unloading page effect
        return () => {

        }

    }, []);

    const product = productList[selectedProductId]

    // ---
    function onDeleteItemRow(row) {
        dispatchToStore(stockProductsDelete({
            product_id: row["product_id"],
            name: row["name"],
            desc: row["desc"],
        }))
    }

    return (
        <Grid container spacing={theme.spacing(4)} sx={{padding: theme.spacing(2)}}>
            <Grid item xs={12}>
                <CustomCard caption={"Delete Product"}>
                    <Stack direction={"column"} spacing={2}>

                        <TextField id={"name"} label={"Name"} value={product["name"]}
                                   disabled={true} />

                        <TextField id={"desc"} label={"Description"} value={product["desc"]}
                                   disabled={true} />

                        <TextField id={"modified_at"} label={"Modified at"} value={product["modified_at"]}
                                   disabled={true} />

                        <DisplayExpectedProps expectedProps={product["expected_props"]}/>

                        <Stack direction={"row"} alignItems={"center"} gap={1} justifyContent={"center"}>

                            <Button size={"large"}
                                    color={"primary"}
                                    sx={{marginTop: 4}}
                                    component={RouterLink}
                                    to={'/settings/products'}

                                    onClick={() => {
                                        onDeleteItemRow(product)
                                    }}>
                                <SaveIcon sx={{marginRight: 2}}/>
                                Delete
                            </Button>

                            <Button size={"large"}
                                    color={"primary"}
                                    sx={{marginTop: 4}}
                                    component={RouterLink}
                                    variant={"outlined"}
                                    to={'/settings/products'}

                                    onClick={() => {

                                    }}>
                                <CloseIcon sx={{marginRight: 2}}/>
                                Cancel
                            </Button>


                        </Stack>
                    </Stack>
                </CustomCard>
            </Grid>

        </Grid>

    )


}

