import {put} from 'redux-saga/effects';
import {userLoginFailed, userLoginOk} from '../slices/userLogin'
import {configurationSetDoneStatus} from "../slices/configuration";
import {snackbarEnqueue} from "../slices/snackbar";
import {apiRequest} from "./apiConnector";
import {apiConnectorInit, apiConnectorStart, apiConnectorUpdateAuth} from "../slices/apiConnector";
import {localStorageSessionKey} from "../../components/generic/utils";

export function* initializeUserAccess(){
    yield put(configurationSetDoneStatus({name: "userAccess", done: false}))

    // \TODO
    // ...
    // userLoginAccessUpdate(..)


    yield put(configurationSetDoneStatus({name: "userAccess", done: true}))
}

export function* resetUserLogin(){
}

export function* restartFromSessionUserLogin(action){
    const sessionId = localStorage.getItem(localStorageSessionKey)

    const options = {
        session: sessionId,
    }

    yield internalCheckUserLogin(options)
}

export function* checkUserLogin(action) {

    const options = {
        user_name: action.payload.user,
        user_pass: action.payload.pass,
    }

    yield internalCheckUserLogin(options)
}

function* internalCheckUserLogin(options){
    yield put(apiConnectorInit())
    yield put(apiConnectorStart(options))

    const [api_response, success] = yield apiRequest("user", "login", {})

    if (!success) {
        if (options.session !== undefined){
            // login by session, session expired
            console.info("session expired")
            yield put(userLoginFailed())

            yield put(snackbarEnqueue({
                message: "Session expired. Please login",
                severity: "info"
            }))

        } else {
            console.error("login failed")
            yield put(userLoginFailed())

            yield put(snackbarEnqueue({
                message: "Login failed " + api_response.info,
                severity: "error"
            }))
        }

    } else {

        console.info("login ok")

        const userInfo = api_response.data.user
        console.debug("user", userInfo)

        // extract session id
        const sessionId = api_response.data.session

        // update local storage
        localStorage.setItem(localStorageSessionKey, sessionId)

        // update apiConnector
        yield put(apiConnectorUpdateAuth({session: sessionId}))

        yield put(userLoginOk({user_name: userInfo.name}))

        yield put(snackbarEnqueue({
            message: "Login was successful for user " + userInfo.name,
            severity: "success"
        }))

    }

}
