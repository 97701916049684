import {createSlice} from '@reduxjs/toolkit'

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState: {
        items: [],
        open: false,
        severity: "info",
        message: "default message - you shouldn't have seen me"
    },

    reducers: {
        snackbarPush: (state, {payload}) => {
            state.open = true
            state.severity = payload.severity
            state.message = payload.message
        },
        snackbarHide: (state, {payload}) => {
            state.open = false
        },


        snackbarEnqueue: (state, { payload }) => {
            const key = payload.options && payload.options.key;
            const msg = {
                key: key || new Date().getTime() + Math.random(),
                message: payload.message,
                options: {
                        variant: payload.severity,
                }
            }

            console.info("snackbar:", msg)
            state.items.push(msg)
        },
        snackbarClose: (state, { payload }) => {
            state.items = state.items.map(item => (
                (!payload || item.key === payload)
                    ? { ...item, dismissed: true }
                    : { ...item }
            ))
        },
        snackbarRemove: (state, { payload }) => {
            state.items = state.items.filter(
                item => item.key !== payload,
            )
        },

    }
})

export const {
    snackbarEnqueue, snackbarClose, snackbarRemove,

    snackbarPush, snackbarHide
} = snackbarSlice.actions

export default snackbarSlice.reducer