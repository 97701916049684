import {
    Collapse,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import {DashboardTwoTone} from "@mui/icons-material";
import {Link as RouterLink, useLocation} from "react-router-dom";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {globalStyles} from "../../style/globalStyles";
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import {useState} from "react";
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import StorageIcon from '@mui/icons-material/Storage';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import CloudDownloadTwoToneIcon from '@mui/icons-material/CloudDownloadTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';

const menuStyle = {
    selected: {
        background: "#888888",
    },
}

function LeftMenuButton(props) {
    const link = props.link
    const label = props.label
    const icon = props.icon !== undefined ? props.icon : ""
    const exact_match = props.exact_match

    const location = useLocation()
    const selected_menu = exact_match ? location.pathname === link : location.pathname.startsWith(link)

    const buttonStyle = selected_menu ? {
        background: menuStyle.selected.background,
    } : {}

    return (
        <ListItem key={label} disablePadding>
            <ListItemButton
                sx={buttonStyle}
                component={RouterLink}
                to={link}
            >

                <ListItemIcon>
                    {icon}
                </ListItemIcon>

                <ListItemText primary={label}/>

            </ListItemButton>

        </ListItem>
    )
}

function SubMenuItemFoldable(props) {
    const label = props.label
    const link = props.link
    const background = props.background
    const icon = props.icon !== undefined ? props.icon : false

    const collapsed = props.collapsed
    const onCollapse = props.onCollapse

    const location = useLocation()
    const selected_menu = location.pathname.startsWith(link)

    let buttonStyle = selected_menu ? {
        fontWeight: "bold"
    } : {}

    if (background) {
        buttonStyle = {...buttonStyle, background: background, color: "white"}
    }

    return <ListItem
        disablePadding
        secondaryAction={
            <IconButton edge={"end"} onClick={() => {
                onCollapse(label)
            }}>
                {collapsed ?
                    <ExpandLessIcon/>
                    :
                    <ExpandMoreIcon/>
                }
            </IconButton>
        }
    >
        <ListItemButton
            sx={buttonStyle}
            onClick={() => {
                onCollapse(label)
            }}
        >
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : <></>}
            <ListItemText primary={label}/>
        </ListItemButton>

    </ListItem>
}

function SubMenuItemAction(props) {
    const label = props.label
    const link = props.link
    const icon = props.icon !== undefined ? props.icon : false
    const exact_match = props.exact_match !== undefined ? props.exact_match : false

    const location = useLocation()
    const selected_menu = exact_match ? location.pathname === link : location.pathname.endsWith(link)

    const buttonStyle = selected_menu ? {
        background: menuStyle.selected.background ,
        fontWeight: "bold"
    } : {}

    return (
        <ListItem key={label} disablePadding dense sx={{paddingLeft: "16px"}}>
            <ListItemButton
                sx={buttonStyle}
                component={RouterLink}
                to={link}
            >

                {icon ? <ListItemIcon>{icon}</ListItemIcon> : <></>}
                <ListItemText primary={label}/>

            </ListItemButton>

        </ListItem>
    )
}

function SubMenu(props) {
    const label = props.menu.label
    const icon = props.menu.icon
    const background = props.background !== undefined ? props.background : false
    const link = props.menu.url
    const subItems = props.menu.subItems !== undefined ? props.menu.subItems : {}

    const collapsed = props.collapsed
    const onCollapse = props.onCollapse

    return (<List disablePadding>

        <SubMenuItemFoldable label={label} collapsed={collapsed} onCollapse={onCollapse} link={link} icon={icon}
                             background={background}/>
        <Collapse in={collapsed} timeout="auto" unmountOnExit>

            {Object.entries(subItems).map(([id, item]) => {
                return <SubMenuItemAction
                    key={id}
                    label={item.label}
                    icon={item.icon}
                    link={item.url}
                    exact_match={true}
                />
            })}

        </Collapse>

    </List>)

}

export default function LeftMenu() {
    const dw = globalStyles.appMenu.drawerWidth

    const dashboardIcon = <DashboardTwoTone/>
    const inventoryIcon = <Inventory2TwoToneIcon />
    const aboutIcon = <HelpTwoToneIcon />

    const [settingsCollapsed, setSettingsCollapsed] = useState(false)
    const menu = {
        label: "Settings",
        icon: <SettingsTwoToneIcon />,
        subItems: [
            {
                label: "Products",
                icon: <StorageIcon />,
                url: "/settings/products"
            },
            {
                label: "Properties",
                icon: <AccountTreeTwoToneIcon />,
                url: "/settings/properties"
            },
            {
                label: "Locations",
                icon: <TravelExploreIcon />,
                url: "/settings/locations"
            },
            {
                label: "Export",
                icon: <CloudDownloadTwoToneIcon />,
                url: "/settings/export"
            }
        ],
    }

    return (
        <Drawer
            sx={{
                width: dw,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: dw,
                    boxSizing: 'border-box',
                },
            }}
            variant="permanent"
            anchor="left"
            open={true}
        >
            <Toolbar/>

            <List disablePadding>
                <LeftMenuButton icon={dashboardIcon} label={"Dashboard"} link={"/"} exact_match={true}/>
            </List>

            <List disablePadding>
                <LeftMenuButton icon={inventoryIcon} label={"Devices"} link={"/stock_devices"} exact_match={true}/>
            </List>

            <SubMenu menu={menu}
                     collapsed={settingsCollapsed}
                     onCollapse={()=>setSettingsCollapsed(!settingsCollapsed)}
            />

            <List disablePadding>
                <LeftMenuButton icon={aboutIcon} label={"About"} link={"/about"}/>
            </List>

        </Drawer>

    )
}