import {configureStore} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import rootSaga from './sagas/rootSaga'
import apiConnectorReducer from './slices/apiConnector'
import userLoginReducer from './slices/userLogin'
import logger from 'redux-logger'
import headerReducer from "./slices/header";
import configurationReducer from './slices/configuration'
import userSettingsReducer from './slices/userSettings'
import snackbarReducer from "./slices/snackbar";
import stockDevicesReducer from './slices/stock/devices'
import stockProductsReducer from "./slices/stock/products"
import stockLocationsReducer from "./slices/stock/locations"
import stockPropertiesReducer from "./slices/stock/properties"
import stockExportReducer from "./slices/stock/export";
import {isDevEnvironment} from "../components/generic/utils";

let store = {};

export default function configureAppStore(preloadedState) {
    const sagaMiddleware = createSagaMiddleware()
    console.info("----- create store")
    const reducer = {
        // there are checked
        apiConnector: apiConnectorReducer,
        userLogin: userLoginReducer,
        header: headerReducer,
        configuration: configurationReducer,
        snackbar: snackbarReducer,
        // check these for validity
        userSettings: userSettingsReducer,
        //
        stockDevices: stockDevicesReducer,
        stockProducts: stockProductsReducer,
        stockLocations: stockLocationsReducer,
        stockProperties: stockPropertiesReducer,
        stockExport: stockExportReducer,
    }

    const my_middleware = [
        //circuitSelectionMiddleware,
        sagaMiddleware,
    ]

    if (isDevEnvironment()) {
        my_middleware.push(logger)
    }

    const enhancers = [
        // circuitSelectionEnhancer
    ]

    store = configureStore({
        reducer,
        middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({serializableCheck: false}), ...my_middleware],
        preloadedState,
        enhancers,
        devTools: isDevEnvironment(),
    })

    sagaMiddleware.run(rootSaga)

    return store
}

export function dispatchToStore(action) {

    store.dispatch(action)
}

export function getStateFromStore(){
    // this function gets the state from vanilla javascript, outside the react component
    // we should avoid using that
    return store.getState()
}