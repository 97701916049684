import {createSlice} from '@reduxjs/toolkit'


export const headerSlice = createSlice({
    name: 'header',
    initialState: {

    },

    reducers: {

    }
})

export const {

} = headerSlice.actions

export default headerSlice.reducer