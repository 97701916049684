import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import {useEffect} from "react";

import {UserLogin} from "../User/UserMenu";
import Stack from "@mui/material/Stack";
import {globalStyles} from "../../style/globalStyles";
import Typography from "@mui/material/Typography";
import {versionNumber} from "../About/Version";
import Box from "@mui/material/Box";

const sensry_logo = new URL('../../assets/logo_large.png', import.meta.url)

export default function PageHeader() {

    useEffect(() => {
        // loading page effect
        console.log("load header")

        // unloading page effect
        return () => {
            console.log("unloaded header")
        }

    }, []);

    return (
        <AppBar position="fixed" sx={globalStyles.appBar}>
            <Toolbar sx={globalStyles.toolbar}>
                <img src={sensry_logo} alt="sensry logo" height="43"/>
                <Stack direction={"row"} alignItems={"flex-end"}>
                    <Typography color={"primary"} variant={"h4"}>Sry Warehouse</Typography>
                    <Typography color={"primary"} variant={"subtitle1"}>{versionNumber}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center">
                    <UserLogin/>
                </Stack>

            </Toolbar>

        </AppBar>
    );
};