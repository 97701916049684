import {put} from "redux-saga/effects";
import {apiRequest} from "../apiConnector";
import {snackbarEnqueue} from "../../slices/snackbar";
import {configurationSetDoneStatus} from "../../slices/configuration";
import {waitFor} from "../../../components/generic/utils";
import {
    stockDevicesLoad,
    stockDevicesLoaded,
    stockDevicesPublicInfoLoaded,
} from "../../slices/stock/devices";

export function* loadStockDevices(action) {

    const [responseList, success] = yield apiRequest("stock-device", "list", {})
    if (!success) {
        return
    }

    yield put(stockDevicesLoaded({devices: responseList.data.list}))
}

export function* upsertStockDevices(action) {

    const data = action.payload

    const [upsertDevice, success] = yield apiRequest("stock-device", "upsert", data)
    if (!success) {
        // error
        yield put(snackbarEnqueue({
            message: "update failed on device " + action.payload.serial + " " + upsertDevice.info,
            severity: "error"
        }))

        return
    }

    // get the newly created device
    const device = upsertDevice.data

    // update props
    for (const i in data.props){
        console.log(i, data)
        const prop = data.props[i]
        if (prop.allow_edit || (prop.value === "")){

            const [upsertProperty, success] = yield apiRequest("stock-properties", "upsert", {
                serial: device.serial,
                name: prop.name,
                type: prop.type,
                value: prop.value,
            })

            if (!success){
                // error
                yield put(snackbarEnqueue({
                    message: "update failed on device " + device.serial + " " + upsertProperty.info,
                    severity: "error"
                }))

                return
            }
        }


    }

    yield put(snackbarEnqueue({
        message: "update success on device " + device.serial,
        severity: "success"
    }))

    // force reload
    yield put(stockDevicesLoad())
}

export function* deleteStockDevices(action){

    const data = action.payload

    const [responseList, success] = yield apiRequest("stock-device", "delete", data)

    if (!success) {
        // error
        yield put(snackbarEnqueue({
            message: "delete failed on device " + action.payload.serial + " " + responseList.info,
            severity: "error"
        }))

    } else {
        // ok

        yield put(snackbarEnqueue({
            message: "delete success on device " + action.payload.serial,
            severity: "success"
        }))
    }

    yield put(stockDevicesLoad())
}

export function* initializeStockDevices(action) {

    yield put(configurationSetDoneStatus({name: "stockDevices", done: false}))

    // start
    yield put(stockDevicesLoad({}))

    // wait for products to be loaded
    yield waitFor(stockDevicesLoaded, state => state.stockDevices.loaded)

    yield put(configurationSetDoneStatus({name: "stockDevices", done: true}))

}

export function * loadPublicDeviceInfo(action){

    const data = action.payload

    const [responseList, success] = yield apiRequest("public-device", "info", data)
    if (!success) {
        return
    }

    yield put(stockDevicesPublicInfoLoaded({device: responseList.data}))
}