import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {userLoginStart} from "../../store/slices/userLogin";
import Grid from "@mui/material/Grid";
import {Button, Card, CardContent, Stack, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import {dispatchToStore} from "../../store/configureStore";
import {useSelector} from "react-redux";


export default function LoginPage(props) {
    let navigate = useNavigate();

    const loggingIn = useSelector(state => state.userLogin.loggingIn)
    const loginCheckOk = useSelector(state => state.userLogin.loginCheckOk)

    const version = useSelector(state=> state.configuration.version)

    const defaultValues = {
        username: "",
        password: "",
    };

    const [formValues, setFormValues] = useState(defaultValues);


    useEffect(() => {
        // loading page effect

        return () => {
            // unloading page effect

        }

    }, []);

    function submit(e) {
        e.preventDefault();

        dispatchToStore(userLoginStart({
            user: formValues.username,
            pass: formValues.password,
        }))

        navigate("/", {replace: true})
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{minHeight: '100vh', backgroundImage: "url(/page_background.png)", backgroundSize: "cover"}}>

            <Grid item xs={6}>
                <Card>
                    <CardContent>

                        <h2>Login</h2>
                        <h6>{version}</h6>
                        <form name="form" onSubmit={submit}>
                            <Stack spacing={2} direction="column">


                                <TextField
                                    id="username"
                                    variant="outlined"
                                    type="text"
                                    className="form-control"
                                    label="username"
                                    name="username"
                                    defaultValue={formValues.username}
                                    onChange={handleInputChange}
                                />

                                <TextField
                                    id="password"
                                    variant="outlined"
                                    type="password"
                                    className="form-control"
                                    label="password"
                                    name="password"
                                    onChange={handleInputChange}
                                />

                                <Box>
                                    <Button variant="contained" type="submit" color="primary">Login</Button>

                                    {loggingIn && "trying to login"}
                                    {loginCheckOk !== undefined ? loginCheckOk ? "login ok" : "login failed" : ""}

                                </Box>
                            </Stack>
                        </form>

                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )


}

