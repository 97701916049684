import CustomCard from "../../components/generic/CustomCard";
import {useParams} from "react-router";
import {theme} from "../../style/theme";
import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {dispatchToStore} from "../../store/configureStore";
import {stockDevicesPublicInfoLoad} from "../../store/slices/stock/devices";
import {useSelector} from "react-redux";
import {Stack, TextField} from "@mui/material";


export default function DeviceInfo() {
    const {device_serial} = useParams()

    const publicDeviceInfo = useSelector(state=>state.stockDevices.publicDeviceInfo)

    const [device, setDevice] = useState(null)

    useEffect(() => {
        // loading page effect
        console.log("device info loading")
        dispatchToStore(stockDevicesPublicInfoLoad({serial: device_serial}))

        // unloading page effect
        return () => {

            console.log("device info unloading")
        }

    }, [])


    useEffect(() => {
        if (publicDeviceInfo.device === null) {
            return
        }

        setDevice({...publicDeviceInfo.device})

        // unloading page effect
        return () => {

        }

    }, [publicDeviceInfo.device]);

    console.warn(device)
    return (
        <Grid container spacing={theme.spacing(2)} sx={{padding: theme.spacing(2)}}>
            <Grid item xs={12}>
                <CustomCard caption={"Device " + device_serial}>
                    {device === null?
                        "loading ..."
                        :
                        <Stack direction={"column"} spacing={2}>

                            <TextField id={"product"} label={"Product"} value={device["product"]} disabled={true}/>

                            <TextField id={"location"} label={"Location"} value={device["location"]} disabled={true}/>

                            <TextField id={"modified_at"} label={"Modified at"} value={device["modified_at"]} disabled={true}/>

                            <TextField id={"created_at"} label={"Created at"} value={device["created_at"]} disabled={true}/>

                            {Object.entries(device["props"]).map(([prop_id, prop]) => {
                                return(<TextField key={prop_id} id={prop_id} label={prop["desc"]}
                                                      value={prop["value"]} disabled={true}/>)
                            })}

                        </Stack>

                    }
                </CustomCard>
            </Grid>
        </Grid>
    )
}