import {createTheme} from '@mui/material/styles';
import './fonts/exo_regular.css'

export const theme = createTheme({

    palette: {
        type: "light",
        primary: {
            main: '#31ac96',
        },
        secondary: {
            main: '#f7f7f7',
        },
        error: {
            main: 'rgb(255, 57, 0)',
        },
        warning: {
            main: 'rgb(255, 186, 0)',
        },
        info: {
            main: 'rgb(220, 219, 219)',
        },
        success: {
            main: 'rgb(76, 154, 64)',
            light: 'rgb(207, 249, 229)',
        },
        divider: 'rgb(220,219,219)'
    },
    typography: {
        fontFamily: ["Exo", "sans-serif"].join(',')
    },
    shape: {
        borderRadius: 6,
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "white",
                    color: "black"
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&$selected": {
                        backgroundColor: "red",
                        color: "white",
                        "& .MuiListItemIcon-root": {
                            color: "white"
                        }
                    },
                    "&:hover": {
                        backgroundColor: '#585857',
                        color: "white"
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                }
            }
        }

    }

});